/* eslint-disable no-undef */
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import PortraitModeWarning from "./pages/commone/portrainModeWarning";
import WebinarRoomPopup from './pages/webinar-hall/webinar-room';

const roomName = "webinar-room";
const expoName = "goa-virtual-meet";
const sceneName = "webinar-room";

export class PopupDialog2 extends Component {
  constructor(props) {
      super(props);
      this.state = {
      roomPopupOpen : false,
      roomName : "",
      roomId : ''
      }
  }

  onClose = () => {
    this.props.onClose()
}

closeGameModal = () => {
  this.setState({ roomPopupOpen: false });
};
onLabelClick=(roomname,roomid)=>{
  this.setState({
    roomPopupOpen : true ,
    roomName : roomname,
    roomId : roomid
  })
}

  render() {
    const { isOpen, onClose, url, styles } = this.props;
    return (
      <div>
        <PortraitModeWarning />

        <Dialog
          open={isOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img src={url}style={{position:"relative"}} />
          {/* <div style={{backgroundImage:`${url}`}}> */}
          <div style={{position:"absolute", left:"50%" , transform:"translate(-50%,-50%)", top:"50%", textAlign:"center", }}>
          <p style={{color:"#055070", fontWeight:"800", whiteSpace: "nowrap"}} onClick={()=>{window.location.href = '/webinar-hall15'}}>Festival of Games and Toys</p>
          <p  style={{color:"#055070" , fontWeight:"800", whiteSpace: "nowrap", marginBottom: "10px"}} onClick={()=>this.onLabelClick("Global Indian scientists & technocrats meet",5)}>Global Indian Scientists & Technocrats Meet </p>
          <p  style={{color:"#055070" , fontWeight:"800", whiteSpace: "nowrap", marginBottom: "10px"}} onClick={()=>this.onLabelClick("ECO fest",6)}>ECO Fest </p>
          <p  style={{color:"#055070" , fontWeight:"800", whiteSpace: "nowrap", marginBottom: "10px"}} onClick={()=>{ window.location.href = '/webinar-hall22'}}>New Edge Technology Show</p>
          <p  style={{color:"#055070" , fontWeight:"800", whiteSpace: "nowrap", marginBottom: "10px"}} onClick={()=>this.onLabelClick("National social organisation & institutions meet",7)}>National Social Organisation & Institutions Meet</p>

          </div>
  </Dialog>

  <WebinarRoomPopup
                 open={this.state.roomPopupOpen}
                 onClose={this.closeGameModal}
                 roomName = {this.state.roomName}
                 roomId = {this.state.roomId}
                 />
       
      </div>
    );
  }
}

export default PopupDialog2;