import React, { Component } from "react";
import webinarHall2d from "../images/webinar/esf.jpg";
// import "./css/main.css"
import videojs from "video.js";
import Header from "../header/header";
import Footer from "../footer/footer-page";
import ChatPanel from "../chatpanel/ChatPanel";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";
import PortraitModeWarning from "../commone/portrainModeWarning";
import RedirectEventArea from "./redirectComponent";

const liveFormat = "application/x-mpegURL";
const liveVideoSrc = "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8";

const roomName = "webinar-hall8";
const expoName = "goa-virtual-meet";
const sceneName = "webinar-hall8";
export class WebinarHall8 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("email") && localStorage.getItem("uid")) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }
    // videojs("liveVideo").src({
    //   type: liveFormat,
    //   src: liveVideoSrc ? liveVideoSrc : "",
    // });
    // this.changeStateForLoader();

    localStorage.setItem("Location", "webinar-hall8");

    // this.updateCollection();
    updateLastSceneEndTime(roomName);
  }

  changeStateForLoader = (value) => {
    videojs("liveVideo").play();

    // this.setState({isloading:value})
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        <Header headerType="user" />
        <img
          alt="webinarImage"
          className="imageClass"
          src={webinarHall2d}
        ></img>
        {/* <video
                id="liveVideo"
                src=""
                loop
                type="application/x-mpegURL"
                className="audiVideo video-js vjs-default-skin"
                crossOrigin="anonymous"
                playsInline
              /> */}
        {/* <div style={{ padding: "56.25% 0 0 0", position: "relative" , overflow:"hidden"}}> */}
        <iframe
          src="https://vimeo.com/event/1644537/embed"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: "18.45vh",
            height: "33vh",
            left: "34.2vw",
            width: "28.9vw",
            background: "black",
            objectFit: "fill",
            overflow: "hidden",
          }}
          title="Goa Test Event"
        ></iframe>
        {/* </div> */}
        <script src="https://player.vimeo.com/api/player.js"></script>
        <RedirectEventArea />
        <ChatPanel roomName="webinar-hall8" />
        <Footer />
      </div>
    );
  }
}

export default WebinarHall8;
