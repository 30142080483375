import React, { Component } from "react";
import loginPageImg from "../images/iisf-bgLogin.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./login-page.css";
import loginFirebase from "../functions/features/login/login-firebase";
import { analyticsFunction } from "../functions/helpers/analytics-function";
import AlertDialog from "../../AlertDialog";
import Marquee from "../Marquee";

export default class Registration extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            isLoader: false,
            alertDialog: {
                isOpen: false,
                title: "",
                subTitle: ""
              },
        }
    }

    setAlertDialog = () => {
        this.setState({
          alertDialog: {
            isOpen: false,
            title: "",
            subTitle: ""
          }
        }, ()=>{
            this.setState({
                isLoader : false
            })
        });
      };

  onSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
        isLoader : true
    })

    let regName = document.getElementById("registration-name-txt").value;
    let regPassword = document.getElementById("registration-dob-txt").value;
    let regEmail = document.getElementById(
      "registration-primary-email-txt"
    ).value;
    let userPhoneNo = document.getElementById("registration-num-txt").value;
    let userState = document.getElementById("registration-state-txt").value;
    let userCity = document.getElementById("registration-city-txt").value;
    // let expoName = document.getElementById('expoName').value;
    // expoName = expoName.toLowerCase();
    // const projectId = document.getElementById('project').value;
    var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
    regEmail = regEmail.toLowerCase();

    if (regName === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter a valid Name",
              subTitle: ""
            }
          });
    //   alert("Please enter a valid Name");
    } else if (regEmail === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter a valid Email-id",
            }
          });
    //   alert("Please enter a valid Email-id");
    } else if (regPassword === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter a valid Password",
            }
          });
    //   alert("Please enter a valid Password");
    } else if (userPhoneNo === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter Phone No",
            }
          });
    //   alert("Please enter Phone No");
    } else if (userState === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter State",
            }
          });
    //   alert("Please enter State");
    } else if (userCity === "") {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter City",
            }
          });
    //   alert("Please enter City");
    } else {
      if (!patternEmail.test(regEmail)) {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Please enter a valid Email-id",
            }
          });
        // alert("Please enter a valid Email-id");
      } else {
        let NewAuth = new Promise((resolve, reject) => {
          loginFirebase
            .auth()
            .createUserWithEmailAndPassword(regEmail, regPassword)
            .then(() => {
              resolve("User Added");
            })
            .catch(function (error) {
              // Handle Errors here.
              reject(error);
              console.log(`error`, error);
              this.setState({
                alertDialog: {
                  isOpen: true,
                  title: `${error}`,
                }
              });
            //   alert(error);
              // ...
            });
        });

        NewAuth.then((result) => {
          if (result === "User Added") {
            this.RegisterOnDatabase();
          } else {
            this.setState({
                alertDialog: {
                  isOpen: true,
                  title: "You are already register",
                  subTitle: ""
                }
              });
            // alert("You are already register");
          }
        });
      }
    }
  };

  RegisterOnDatabase = () => {
    let regName = document.getElementById("registration-name-txt").value;
    let userDob = document.getElementById("registration-dob-txt").value;
    let regEmail = document.getElementById(
      "registration-primary-email-txt"
    ).value;
    let userPhoneNo = document.getElementById("registration-num-txt").value;
    let userState = document.getElementById("registration-state-txt").value;
    let userCity = document.getElementById("registration-city-txt").value;

    // console.log(`regName`, regName)
    // console.log(`userDob`, userDob)
    // console.log(`regEmail`, regEmail)
    // console.log(`userPhoneNo`, userPhoneNo)
    // console.log(`userState`, userState)
    // console.log(`userCity`, userCity)

    const db = loginFirebase.firestore();
    let NewDatabase = new Promise((resolve, reject) => {
      // Add a new document with a generated id.
      db.collection("users")
        .add({
          parti_contact_number: userPhoneNo,
          parti_primary_email: regEmail,
          parti_name: regName,
          parti_dob: userDob,
          parti_city: userCity,
          parti_state: userState,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
          resolve("User Added");
          localStorage.setItem("name", regName);
          localStorage.setItem("country", userState);
          localStorage.setItem("uid", docRef.id);
          localStorage.setItem("email", regEmail);
          localStorage.setItem("mobile", userPhoneNo);
          localStorage.setItem("roomName", "entrance");
          window.location.href = "/events";
          analyticsFunction("exterior");
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
          reject(error);
          this.setState({
            alertDialog: {
              isOpen: true,
              title: `${error}`,
            }
          });
        //   alert(error);
        });
    });
    NewDatabase.then((result) => {
      if (result === "User Added") {
        this.setState({
            isLoader : false
        })
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Your registration is done successfully !" ,
            }
          });
        // alert("SuccesFully register");
      }
    }).catch((error) => {
        this.setState({
            alertDialog: {
              isOpen: true,
              title: "Database entry error" + `${error}`,
            }
          });
    //   alert("Database entry error " + error);
    });
  };

  gotoLoginPage(e) {
    e.preventDefault();
    window.location.href = "/";
  }
  render() {
    return (
      <div>
        <div id="content-wrapper">
          <div className="blocks-container">
            <div className="block type-1 scroll-to-block" data-id="register">
              <div
                className="container-fluid type-1-text wow flipInX "
                data-wow-delay="0.3s"
              >
                {/* <Marquee /> */}
                <div className="row">
                  <div
                    className="col-md-4 col-lg-4 col-4  type-1-center"
                    style={{ textAlign: "left", paddingLeft: 0 }}
                  >
                    <div>
                      <img src={loginPageImg} alt="" className="login-image" />
                    </div>
                  </div>

                  <div
                    id="registration-form"
                    style={{ display: "block" }}
                    className="col-md-8 col-lg-8 col-12 type-1-center"
                  >
                    <div className="centered flex--form">
                      <h5 className="mb-3 colored-text">
                        Register to IISF 2021
                      </h5>

                      <form>
                        <label
                          htmlFor="registration-name-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant Name
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-name-txt"
                          style={{ width: "100%" }}
                          type="text"
                          placeholder="Participant Name"
                          name="participantName"
                        />

                        <label
                          htmlFor="registration-dob-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant Date of Birth
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-dob-txt"
                          style={{ width: "100%" }}
                          type="date"
                          placeholder="Participant Date of Birth"
                          name="participantDob"
                        />

                        <label
                          htmlFor="registration-primary-email-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant Primary Email Id
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-primary-email-txt"
                          style={{ width: "100%" }}
                          type="email"
                          placeholder="Participant Primary Email Id"
                          name="participantEmail"
                        />

                        <label
                          htmlFor="registration-num-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant Contact Number
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-num-txt"
                          style={{ width: "100%" }}
                          type="tel"
                          placeholder="Participant Contact Number"
                          name="participantNum"
                        />

                        <label
                          htmlFor="registration-state-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant State
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-state-txt"
                          style={{ width: "100%" }}
                          type="text"
                          placeholder="Participant State"
                          name="participantState"
                        />

                        <label
                          htmlFor="registration-city-txt"
                          className="w-100 text-start mb-1 fs-1"
                        >
                          Participant City
                        </label>
                        <input
                          className="required inputclass mb-2"
                          id="registration-city-txt"
                          style={{ width: "100%" }}
                          type="text"
                          placeholder="Participant City"
                          name="participantCity"
                        />

                        {/* <input
                                                className="required inputclass"
                                                id="login-password-txt"
                                                autoComplete="false"
                                                style={{ width: "100%" }}
                                                type="password"
                                                placeholder="*Password"
                                                name="password"
                                            /> */}

                        <div className="submit-wraper mt-2">
                          <button
                            className="loginbutton"
                            style={{ marginBottom: "8px", outline: 0 }}
                            id="login-btn"
                            onClick={this.onSubmitForm}
                          >
                            <div id="login-name" style={{ display: "flex" , justifyContent :"center" }}>
                              Register
                              {this.state.isLoader && (
                                <div className="loader" id="btn-loader" />
                              )}
                            </div>
                            <div
                              className="loaderBtn"
                              id="loader-login"
                              style={{ display: "none" }}
                            ></div>
                          </button>

                          <div>
                            <button
                              className="register-btn"
                              type="button"
                              onClick={(e) => this.gotoLoginPage(e)}
                              style={{ marginBottom: "8px" }}
                            >
                              Login Here
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlertDialog
          alertDialog={this.state.alertDialog}
          setAlertDialog={this.setAlertDialog}
        />
      </div>
    );
  }
}
