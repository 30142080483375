import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../helpers/footer/footer.css";
import "./agenda-style.css";
import lobbyImg from "../images/footer-icons/reception.png";
import circleImg from "../images/footer-icons/01.png";
import gamezoneImg from "../images/footer-icons/game-room.png";
import webinarImg from "../images/footer-icons/event-hall.png";
import ArchiveImg from "../images/footer-icons/archive-area.png";
import networkingImg from "../images/footer-icons/networking.png";
import agendaImg from "../images/footer-icons/agenda.png";
import displayGallery from "../images/footer-icons/display-gallery-1.png";
import facultyIcon from "../images/footer-icons/faculty-room.png";
import nurseAgendaImg from "../images/agenda-chart-2.jpg";
import tutorialimg1 from "../images/desktopangels1.jpg";
import tutorialimg2 from "../images/mobangels2.jpg";
import playImg from "../images/medexIcon/play-med.png";
import pauseImg from "../images/medexIcon/pause-med.png";
import closeBtn from "../images/medexIcon/cross.png";
// import videoPosterImg from '../images/emma-video-poster.png';
import menuImg from "../images/menu.png";
import closeImg from "../images/cross.png";
import logoutImg from "../images/footer-icons/log-out.png";
import moreImg from "../images/footer-icons/more.png";
import quizImg from "../images/footer-icons/quiz.png";
import feedbackImg from "../images/footer-icons/feedback.png";
import GameList from "../../gameList";
import loginFirebase from "../functions/features/login/login-firebase";
import AgendaDialog from "../../agenda";

// const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/MEDEX_Emma_Video-c.mp4'
const entranceVideo =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/sample-welcome-video.mp4";

const videoPosterImg =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/sample-welcome-video-poster.png";
const expoName = "goa-virtual-meet";

const agendaImages =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/sample-event-agenda.png";

export default class Footer extends Component {
  state = {
    isBtnLive: false,
    isVideoStart: false,
    gameModalIsOpen: false,
    openAgenda: false,
    openMore: false,
  };

  componentDidMount() {
    if (localStorage.getItem('email') && localStorage.getItem('uid')) {
        console.warn('welcome')
    } else {
        window.location.href = '/'
    }
    this.getUserDetails();
    // var initialScreenSize = window.innerHeight;
    // window.addEventListener("resize", function () {
    //     if (window.innerHeight < initialScreenSize) {
    //         if (document.getElementById("bottommenu")) {
    //             document.getElementById("bottommenu").style.display = "none";
    //         }
    //         if (document.getElementById("quickAccessBtn")) {
    //             document.getElementById("quickAccessBtn").classList.add("btn-hover-shinebottom");
    //         }
    //         if (document.getElementById("Chat")) {
    //             document.getElementById("Chat").classList.add("chaticonpositionbottom");
    //         }
    //         if (document.getElementById("moderator1")) {
    //             document.getElementById("moderator1").classList.add("bottomtotopchat1");
    //         }
    //         if (document.getElementById("message-form")) {
    //             document.getElementById("message-form").classList.add("bottomtotopchat1");
    //         }

    //     } else {
    //         if (document.getElementById("bottommenu")) {
    //             document.getElementById("bottommenu").style.display = "block";
    //         }
    //         if (document.getElementById("quickAccessBtn")) {
    //             document.getElementById("quickAccessBtn").classList.remove("btn-hover-shinebottom");
    //         }
    //         if (document.getElementById("Chat")) {
    //             document.getElementById("Chat").classList.remove("chaticonpositionbottom");
    //         }
    //         if (document.getElementById("moderator1")) {
    //             document.getElementById("moderator1").classList.remove("bottomtotopchat1");
    //         }
    //         if (document.getElementById("message-form")) {
    //             document.getElementById("message-form").classList.remove("bottomtotopchat1");
    //         }
    //     }
    // });
  }

  getUserDetails() {
    let self = this;
    const db = loginFirebase.firestore();
    const userCollection = db.collection("GoaLive");
    // let ExistingUserQuery = new Promise((resolve, reject) => {
    let profilePicArray = [];
    userCollection
      .where("expo", "==", expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {
          if (change.type === "added" && change.type !== "modified") {
            console.log("New city: ", change.doc.data());
            if (change.doc.data().isLive === "true") {
              self.setState({ isBtnLive: true });
            } else if (change.doc.data().isLive === "false") {
              self.setState({ isBtnLive: false });
            }
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            // if (change.type === "added" && change.type !== "modified") {
            console.log("New city: ", change.doc.data());
            if (change.doc.data().isLive === "true") {
              self.setState({ isBtnLive: true });
            } else if (change.doc.data().isLive === "false") {
              self.setState({ isBtnLive: false });
            }

            // }
          }

          // console.log(doc.id, " => ", doc.data());
        });

        // resolve(profilePicArray);
      });
  }

  isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  goToLobby = () => {
    let str = window.location.href;
    // console.warn('string is ::', str.substring(str.lastIndexOf('/')+1))
    if (str.substring(str.lastIndexOf("/") + 1) !== "entrance") {
      window.location.href = "/entrance";
    }
  };

  goToWebinar = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "webinar-hall") {
      window.location.href = "/webinar-hall";
    }
  };

  goToBreakout = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "breakout-page") {
      window.location.href = "/breakout-page";
    }
  };

  goToArchiveGallary = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "archive") {
      window.location.href = "/archive";
    }
  };

  goToNetworkArea = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "network-area") {
      window.location.href = "/network-area";
    }
  };

  goToBreakTimeZone = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "entertainment-zone") {
      window.location.href = "/entertainment-zone";
    }
  };

  goToDisplayGallarySection = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "display") {
      window.location.href = "/display";
    }
  };

  goToMedexFaculty = () => {
    let str = window.location.href;
    if (str.substring(str.lastIndexOf("/") + 1) !== "medex-faculty") {
      window.location.href = "/IISF-faculty";
    }
  };

  openGameModal = () => {
    this.setState({ gameModalIsOpen: true });

    document.getElementById("gameZoneFooter").classList.add("activeflexcolumn");
  };

  closeGameModal = () => {
    this.setState({ gameModalIsOpen: false });

    document
      .getElementById("gameZoneFooter")
      .classList.remove("activeflexcolumn");
  };
  closeAgenda = () => {
    this.setState({ openAgenda: false });

    // document.getElementById("gameZoneFooter").classList.remove("activeflexcolumn")
  };

  // gotoSelfieZone = () => {
  //     let str = window.location.href;
  //     if (str.substring(str.lastIndexOf('/') + 1) !== 'selfie-zone') {
  //         window.location.href = '/selfie-zone'
  //     }
  // }

  goToAgenda = () => {
    this.setState({ openAgenda: true });
    // document.getElementById("location1").style.display = "block";

    // document.getElementById("agendaFooter").classList.add("activeflexcolumn")
  };

  goToInfo = () => {
    document.getElementById("info1").style.display = "block";
  };

  goToFeedback = () => {
    // window.open('https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?event_name=META_ILD_VIRTUAL_MASTERCLASS_2020&country_code=MC', '_blank')
    window.open(
      "https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?event_name=MEDEX_2021_&country_code=MC",
      "_blank"
    );
  };

  userLogout = () => {
    localStorage.clear();
    window.location.href = "/";
    // console.warn('update last scene end time')
    // const db = analyticsFirebase.firestore();
    // db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
    //     endTime: new Date().getTime(),
    // })
    //     .then(function (docRef) {
    //         console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
    //         // currentDoc = docRef;

    //         localStorage.clear()
    //         window.location.href = "/"
    //     })
    //     .catch(function (error) {
    //         console.error("Error adding document: ", error);
    //     });

    // authFirebaseApp.auth().signOut().then(res => {
    //     console.log('log out')
    // })
    // this.props.goToPage(PAGETYPE.ENTERTAINMENT);
  };
  nurseAgenda = () => {
    document.getElementById("nurseAgenda").style.display = "block";
    document
      .getElementById("agendaNurseBtn")
      .setAttribute("class", "btnBlueGreen active");
    document
      .getElementById("agendaNeurologistBtn")
      .setAttribute("class", "btnBlueGreen margin");
    document.getElementById("neurologistAgenda").style.display = "none";
  };

  neurologistAgenda = () => {
    document.getElementById("neurologistAgenda").style.display = "block";
    document
      .getElementById("agendaNeurologistBtn")
      .setAttribute("class", "btnBlueGreen margin active");
    document
      .getElementById("agendaNurseBtn")
      .setAttribute("class", "btnBlueGreen");
    document.getElementById("nurseAgenda").style.display = "none";
  };

  closePopup = () => {
    document.getElementById("location1").style.display = "none";

    document
      .getElementById("agendaFooter")
      .classList.remove("activeflexcolumn");
  };

  infoButtonclose = () => {
    document.getElementById("info1").style.display = "none";
  };

  changeAgendaDate = (activeTabNum) => {
    console.warn("Should open tab", activeTabNum);
    for (let i = 1; i <= 11; i++) {
      document.getElementById("tab-" + i).style.display = "none";

      if (
        document.getElementById("tab-" + i).classList.contains("tab-current")
      ) {
        document.getElementById("tab-" + i).classList.remove("tab-current");
      }

      if (
        document.getElementById("btn-" + i).classList.contains("tab-active")
      ) {
        document.getElementById("btn-" + i).classList.remove("tab-active");
      }
    }
    document.getElementById("tab-" + activeTabNum).style.display = "block";
    document.getElementById("tab-" + activeTabNum).classList.add("tab-current");
    document.getElementById("btn-" + activeTabNum).classList.add("tab-active");
  };

  startVideo = () => {
    this.setState({ isVideoStart: true });
  };

  closeVideo = () => {
    this.setState({ isVideoStart: false });
  };

  playVideo1 = () => {
    if (
      document.getElementById("2dVideo1") &&
      document.getElementById("Pause2dVideo1") &&
      document.getElementById("Play2dVideo1")
    ) {
      document.getElementById("2dVideo1").play();
      document.getElementById("2dVideo1").volume = 1;
      document.getElementById("Pause2dVideo1").style.display = "block";
      document.getElementById("Play2dVideo1").style.display = "none";
      localStorage.setItem("isVideoWatched", "true");
      // document.getElementById('countdown1').style.display = 'block';
    }
  };

  pauseVideo1 = () => {
    if (
      document.getElementById("2dVideo1") &&
      document.getElementById("Pause2dVideo1") &&
      document.getElementById("Play2dVideo1")
    ) {
      document.getElementById("2dVideo1").pause();

      document.getElementById("2dVideo1").volume = 0;
      document.getElementById("Play2dVideo1").style.display = "block";
      document.getElementById("Pause2dVideo1").style.display = "none";
      // document.getElementById('countdown1').style.display = 'none';
    }
  };
  openbottommenu = () => {
    console.log("in bottom menu");
    document.getElementById("bottommenu").classList.remove("bottomaniamtion");
    document.getElementById("Mobmenu").style.display = "none";
    document.getElementById("Mobmenuclose").style.display = "flex";
    if (document.getElementById("myScrollingChat")) {
      document
        .getElementById("myScrollingChat")
        .classList.remove("ishiddenchat");
    }
    if (document.getElementById("Chat")) {
      document.getElementById("Chat").classList.add("chaticonposition2");
    }
  };
  closebottommenu = () => {
    document.getElementById("bottommenu").classList.add("bottomaniamtion");
    document.getElementById("Mobmenu").style.display = "flex";
    document.getElementById("Mobmenuclose").style.display = "none";
    if (document.getElementById("Chat")) {
      document.getElementById("Chat").classList.remove("chaticonposition2");
    }
    // if (document.getElementById("BOTTTOMchatbox"))
    //     document.getElementById("BOTTTOMchatbox").classList.add("bottomchatbox1");
    // if (document.getElementById("message-form"))
    //     document.getElementById("message-form").classList.add("chat-textnormalani");
  };

  displayMoreOptions = () => {
    // document.getElementById("feedbackFormFooter").classList.add("options")
    // document.getElementById("options").classList.toggle("toggleBottom");
    this.setState({
      openMore: !this.state.openMore,
    });
  };

  gotoFeedbackForm = () => {
    window.open("https://8mkd8lvrafe.typeform.com/to/RjpVg3Py", "_blank");
  };

  gotoQuiz = () => {
    window.open("https://8mkd8lvrafe.typeform.com/to/dj2YdmHr", "_blank");
  };

  render() {
    return (
      <div>
        {this.state.isVideoStart && (
          <div>
            <div className="overlayVideo" id="VideoOverlay1">
              {/* <div id="countdown1" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div> */}
            </div>

            <div id="video-popup1">
              <button
                id="closeBtn"
                className="closebtnposition"
                onClick={this.closeVideo}
                style={{ outline: "none" }}
              >
                <img src={closeBtn} alt="play" className="closebtnwidth"></img>
              </button>
              <p className="videoTitle">Guided Tour by Emma</p>
              <video
                id="2dVideo1"
                className="videopopup"
                playsInline
                crossOrigin="anonymous"
                src={entranceVideo}
                poster={videoPosterImg}
                preload="auto"
              ></video>
              <button
                id="Play2dVideo1"
                onClick={this.playVideo1}
                style={{ outline: "none" }}
              >
                <img src={playImg} alt="play" className="playbtnwidth"></img>
                <p className="playvideotext">Play Guided Tour</p>
              </button>
              <button
                id="Pause2dVideo1"
                style={{ display: "none", outline: "none" }}
                onClick={this.pauseVideo1}
              >
                <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                <p className="playvideotext">Pause Guided Tour</p>
              </button>
              {/* <button id="startExperienceButton1" style={{ display: 'none' }} className="startExperianceBtn heightExperiance" onClick={this.startExperiance} >Enter Event</button> */}
            </div>
          </div>
        )}
        {this.state.isBtnLive &&
          localStorage.getItem("Location") !== "webinar-hall" && (
            <button
              id="quickAccessBtn"
              onClick={this.goToWebinar}
              className="btn-hover-shine btn-glow"
            >
              Quick Access to Live Session
            </button>
          )}
        {/* //  } */}
        {/* AGENDA POPUP */}
        <div className="modal" id="location1" style={{ display: "none" }}>
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title green-text" id="staticBackdropLabel">
                  Agenda
                </h5>

                <button
                  type="button"
                  className="close black-color"
                  data-dismiss="modal"
                  onClick={this.closePopup}
                >
                  &times;
                </button>
              </div>
              {/* <div className="agendaButton">
                                <button className="btnBlueGreen active" id='agendaNurseBtn' onClick={this.nurseAgenda}>1</button>
                                <button className="btnBlueGreen margin" id='agendaNeurologistBtn' onClick={this.neurologistAgenda}>2</button>
                            </div> */}

              <section
                id="agenda"
                style={{ height: "400px", overflowY: "auto" }}
              >
                <div className="extra-container" style={{ display: "none" }}>
                  <div className="schedule-list__tittle">
                    <h1>Conference Schedule</h1>
                  </div>
                </div>
                <div className="container">
                  <div className="schedule-tab">
                    <a
                      className="schedule-tab__item tab-active"
                      id="btn-1"
                      data-tab="tab-1"
                      href="#tab-1"
                      onClick={() => this.changeAgendaDate(1)}
                    >
                      <h2>Day 01</h2>
                      <p>22/01/21</p>
                    </a>
                    <a
                      className="schedule-tab__item "
                      id="btn-2"
                      data-tab="tab-2"
                      href="#tab-2"
                      onClick={() => this.changeAgendaDate(2)}
                    >
                      <h2>Day 02</h2>
                      <p>23/01/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-3"
                      data-tab="tab-3"
                      href="#tab-3"
                      onClick={() => this.changeAgendaDate(3)}
                    >
                      <h2>Day 03</h2>
                      <p>26/01/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-4"
                      data-tab="tab-4"
                      href="#tab-4"
                      onClick={() => this.changeAgendaDate(4)}
                    >
                      <h2>Day 04</h2>
                      <p>29/01/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-5"
                      data-tab="tab-5"
                      href="#tab-5"
                      onClick={() => this.changeAgendaDate(5)}
                    >
                      <h2>Day 05</h2>
                      <p>30/01/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-6"
                      data-tab="tab-6"
                      href="#tab-6"
                      onClick={() => this.changeAgendaDate(6)}
                    >
                      <h2>Day 06</h2>
                      <p>02/02/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-7"
                      data-tab="tab-7"
                      href="#tab-7"
                      onClick={() => this.changeAgendaDate(7)}
                    >
                      <h2>Day 07</h2>
                      <p>05/02/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-8"
                      data-tab="tab-8"
                      href="#tab-8"
                      onClick={() => this.changeAgendaDate(8)}
                    >
                      <h2>Day 08</h2>
                      <p>06/02/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-9"
                      data-tab="tab-9"
                      href="#tab-9"
                      onClick={() => this.changeAgendaDate(9)}
                    >
                      <h2>Day 09</h2>
                      <p>09/02/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-10"
                      data-tab="tab-10"
                      href="#tab-10"
                      onClick={() => this.changeAgendaDate(10)}
                    >
                      <h2>Day 10</h2>
                      <p>12/02/21</p>
                    </a>
                    <a
                      className="schedule-tab__item"
                      id="btn-11"
                      data-tab="tab-11"
                      href="#tab-11"
                      onClick={() => this.changeAgendaDate(11)}
                    >
                      <h2>Day 11</h2>
                      <p>13/02/21</p>
                    </a>
                  </div>

                  <div id="tab-1" className="schedule-table tab-current">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Heart Failure- Defining the Challenges </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={andrewCoatsImg} alt="Andrew Coats" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Andrew Coats</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            T2DM: Why did the guidelines change so rapidly
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={aliceChengImg} alt="Alice Cheng" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Alice Cheng </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>CKD: a burden that needs attention?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={merlinThomasImg} alt="Merlin Thomas " /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Merlin Thomas </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-2" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            HFrEF: A burden that needs to be tackled differently{" "}
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={javedButlerImg} alt="Javed Butler" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Javed Butler</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Why early diagnosis of CV/Renal risks T2DM</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={chantelImg} alt="Chantal Mathieu" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Chantal Mathieu</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Updated guidelines- are they applicable?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={perHenrikGroopImg} alt="Per-henrik Groop" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Per-Henrik Groop</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-3" className="schedule-table">
                    <div className="row ">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>60 mins between 9:00 pm and 10:00 pm </h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Treating HFrEF: Should clinical practice be the same
                            around the world? (discussion)
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={javedButlerImg} alt="Javed Butler" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Javed Butler</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={stefanAnkerImg} alt="Stefan Anker" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Stefan Anker</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={zannadImg} alt="Faiez Zannad" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Faiez Zannad</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-4" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            State of the art management of chronic HF, SGLT2i,
                            how do they work?{" "}
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={mcGuireImg} alt="Darren Maguire" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Darren McGuire</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Foundational therapy approach, should SGLT2is be one
                            new foundation of therapy?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={melanieImg} alt="Melanie Davis" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Melanie Davies</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            State of the art management of CKD, Focus on role of
                            new classes
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={wannerImg} alt="Christoph Wanner" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Christoph Wanner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-5" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            EMPEROR Reduced Results: Qualifying SGLT2i role
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={zannadImg} alt="Faiez Zannad" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Faiez Zannad</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            EMPEROR Reduced, what do we need to know about T2DM
                            with HFrEF
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={aliceChengImg} alt="Alice Cheng" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Alice Cheng</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Difficulties we face in clinical practice, do we
                            still need answers?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={perHenrikGroopImg} alt="Per-Henrik Groop" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Per-Henrik Groop</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-6" className="schedule-table">
                    <div className="row ">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>60 mins between 9:00 pm and 10:00 pm </h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Treating T2DM effectively: Should we change our
                            approach or remain as we are?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={melanieImg} alt="Melanie Davis" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Melanie Davies</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={caballeroImg} alt="Enrique Caballero" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Enrique Caballero</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={aliceChengImg} alt="Alice Cheng" /> */}
                            <div className="schedule-table__speaker__name">
                              <p> Alice Cheng</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={peterLinImg} alt="Peter Lin" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Peter Lin</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-7" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            HFrEF: Traditional approach vs innovative- why,
                            what, how and when?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={sanjayKaulImg} alt="Sanjay Kaul" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Sanjay Kaul</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Clinical Approach to management of complicated T2DM?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={melanieImg} alt="Melanie Davis " /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Melanie Davies </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Renal data from HF trials</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={wannerImg} alt="Christoph Wanner" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Christoph Wanner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-8" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            How HF new algorithms of treatment should look like?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={stefanAnkerImg} alt="Stefan Anker" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Stefan Anker</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Clinical Approach to prevent CV risk and CV Death in
                            T2DM, what can be done?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={peterLinImg} alt="Peter Lin" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Peter Lin </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Clinical Approach of HF patients with renal
                            impairment, what can be done?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={merlinThomasImg} alt="Merlin Thomas" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Merlin Thomas</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-9" className="schedule-table">
                    <div className="row ">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>60 mins between 8:00 pm and 9:00 pm </h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            Treating CKD: is there more to be done and when?
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={perHenrikGroopImg} alt="Per-Henrik Groop" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Per-Henrik Groop</p>
                            </div>
                          </div>
                          <div className="schedule-table__speaker__info">
                            {/* <img src={wannerImg} alt="Christoph Wanner" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Christoph Wanner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-10" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            HFrEF vs HFmREF vs HfpEF- similarities and
                            differences
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={sanjayKaulImg} alt="Sanjay Kaul" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Sanjay Kaul</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>
                            The preferred second line therapy after metformin in
                            T2DM: SGLT2i or GLP-1
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={caballeroImg} alt="Enrique Caballero" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Enrique Caballero </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>CKD: when to use SGLT2is and when not to?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={perHenrikGroopImg} alt="Per-henrik Groop" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Per-Henrik Groop </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&amp;A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-11" className="schedule-table">
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:00pm – 08:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Whats on the horizon for HFpEF?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={ponikowskiImg} alt="Piotr Ponikowski" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Piotr Ponikowski</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>08:30pm – 09:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Whats on the horizon for DM?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={kahnStevenImg} alt="Steven Kahn " /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Steven Kahn </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-5">
                        <div className="schedule-table__time">
                          <h3>09:00pm – 09:30pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-7">
                        <div className="schedule-table__event">
                          <h3>Whats on the horizon for CKD?</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            {/* <img src={wannerImg} alt="Christoph Wanner" /> */}
                            <div className="schedule-table__speaker__name">
                              <p>Christoph Wanner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row wow flipInX">
                      <div className="col-lg-3 col-sm-4">
                        <div className="schedule-table__time">
                          <h3>09:30pm – 10:00pm</h3>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-8">
                        <div className="schedule-table__event">
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="schedule-table__speaker">
                          <div className="schedule-table__speaker__info">
                            <div className="schedule-table__speaker__name">
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div className="agendaImage" id='neurologistAgenda' style={{ display: 'none' }}>
                                <img src={neurologistsAgendaImg} style={{ width: '100%' }} />

                            </div> */}
            </div>
          </div>
        </div>
        {/* AGENDA POPUP */}

        {/* INFO POPUP */}
        <div className="modal" id="info1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title green-text" id="staticBackdropLabel">
                  Info
                </h5>

                <button
                  type="button"
                  className="close black-color"
                  data-dismiss="modal"
                  onClick={() => this.infoButtonclose()}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {this.isMobileDevice() ? (
                  <img src={tutorialimg2} style={{ width: "100%" }} />
                ) : (
                  <img src={tutorialimg1} style={{ width: "100%" }} />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* INFO POPUP */}
        <div className="mobmenu" id="Mobmenu">
          <img
            src={menuImg}
            className="widthmenuicon"
            alt=""
            onClick={this.openbottommenu}
          />
        </div>
        <div className="mobmenuclose" id="Mobmenuclose">
          <img
            src={closeImg}
            className="widthmenuicon"
            alt=""
            onClick={this.closebottommenu}
          />
        </div>
        <div id="bottommenu" className="bottomaniamtion">
          <ul
            className="list-unstyled  flexicon scrolltypewhite"
            style={{ transition: "all 0.3s linear" }}
          >
            <li
              className="flexcolumn"
              id="lobbyFooter"
              onClick={this.goToLobby}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={lobbyImg} className="widthicon" alt="Webinar" />
              </div>
              <span className="cenetrtext bottomtext">Lobby</span>
            </li>

            {/* <li
              className="flexcolumn"
              id="webinarHall"
              onClick={this.goToWebinar}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={webinarImg} className="widthicon" alt="Webinar" />
              </div>
              <span className="cenetrtext bottomtext">Webinar Hall</span>
            </li> */}
            {/* <li className="flexcolumn" onClick={this.goToBreakout} >
                            <img src={breakoutimg} className="widthicon" alt="Breakout" />
                            <span className="cenetrtext bottomtext">breakout Room</span>

                        </li> */}
            {/* <li className="flexcolumn" onClick={this.goToBreakout2} >
                            <img src={breakoutimg} className="widthicon" alt="Breakout" />
                            <span className="cenetrtext bottomtext">breakout Room 2</span>

                        </li> */}
            {/* <li className="flexcolumn" onClick={() => this.chatRoomButton(PAGETYPE.CHATROOM)}>
                                <img src={chatroomImg} className="widthicon" alt="Chat" />
                                <span className="cenetrtext bottomtext">Chat Room</span>
                            </li> */}
            {/* <li className="flexcolumn" onClick={this.goToMedexFaculty} id="facultyRoom">
                            <div className="d-flex">
                                <img src={circleImg} className="circleimg" alt="Webinar" />
                                <img src={facultyIcon} className="widthicon" alt="Gallary" />
                            </div>
                            <span className="cenetrtext bottomtext">Faculty Room</span>
                        </li> */}
            <li
              className="flexcolumn"
              id="networkingArea"
              onClick={this.goToNetworkArea}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={networkingImg} className="widthicon" alt="Gallary" />
              </div>
              <span className="cenetrtext bottomtext">Networking Area</span>
            </li>
            <li
              className="flexcolumn"
              id="displayGallery"
              onClick={this.goToDisplayGallarySection}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={displayGallery} className="widthicon" alt="Gallary" />
              </div>
              <span className="cenetrtext bottomtext">e-Poster Hall</span>
            </li>

            <li
              id="expohall"
              className="flexcolumn"
              onClick={this.goToArchiveGallary}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={ArchiveImg} className="widthicon" alt="Gallary" />
              </div>
              <span className="cenetrtext bottomtext">Archive Gallery</span>
            </li>
            {/* <li className="flexcolumn" onClick={this.goToBreakTimeZone}>
                            <img src={entertainmentImg} className="widthicon" data-toggle="modal" alt="Entertainment"
                                data-target="#filterpopup" />
                            <span className="cenetrtext bottomtext">Break Time Zone</span>

                        </li> */}

            {/* <li className="flexcolumn"  >
                            <img src={logout} className="widthicon" alt="Log out" onClick={this.userLogout} />
                            <span className="cenetrtext bottomtext">AR</span>

                        </li> */}

            <li
              className="flexcolumn"
              id="agendaFooter"
              onClick={this.goToAgenda}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={agendaImg} className="widthicon" alt="Location" />
              </div>
              <span className="cenetrtext bottomtext">Agenda</span>
            </li>
            {/* <li className="flexcolumn" onClick={this.goToInfo}>
                            <img src={infoimg} className="widthicon" data-target="#info1" data-toggle="modal" alt="Location" />
                            <span className="cenetrtext bottomtext">Info</span>

                        </li> */}

            <li
              className="flexcolumn"
              id="gameZoneFooter"
              onClick={() => this.openGameModal()}
            >
              <div className="d-flex">
                <img src={circleImg} className="circleimg" alt="Webinar" />
                <img src={gamezoneImg} className="widthicon" alt="Feedback" />
              </div>
              <span className="cenetrtext bottomtext">Game-Zone</span>
            </li>

            {/* <ul className="flexrow" id="options"> */}
            {this.state.openMore && (
              <li
                className="flexcolumn"
                id="feedbackFormFooter"
                style={{ transition: "all 0.3s linear" }}
                onClick={this.gotoFeedbackForm}
              >
                <div className="d-flex">
                  <img src={circleImg} className="circleimg" alt="Webinar" />
                  <img src={feedbackImg} className="widthicon" alt="Log out" />
                </div>
                <span className="cenetrtext bottomtext">Feedback Form</span>
              </li>
            )}

            {this.state.openMore && (
              <li
                className="flexcolumn"
                style={{ transition: "all 0.3s linear" }}
                id="quizFooter"
                onClick={this.gotoQuiz}
              >
                <div className="d-flex">
                  <img src={circleImg} className="circleimg" alt="Webinar" />
                  <img src={quizImg} className="widthicon" alt="Log out" />
                </div>
                <span className="cenetrtext bottomtext">Quiz</span>
              </li>
            )}
            {this.state.openMore && (
              <li
                className="flexcolumn"
                style={{ transition: "all 0.3s linear" }}
                id="logOutFooter"
                onClick={this.userLogout}
              >
                <div className="d-flex">
                  <img src={circleImg} className="circleimg" alt="Webinar" />
                  <img src={logoutImg} className="widthicon" alt="Log out" />
                </div>
                <span className="cenetrtext bottomtext">Log Out</span>
              </li>
            )}
            <li
              className="flexcolumn justify-content-between"
              id="moreOptionsFooter"
              onClick={this.displayMoreOptions}
            >
              <div className="d-flex">
                {/* <img src={circleImg} className="circleimg" alt="Webinar" /> */}
                <img src={moreImg} className="widthicon" alt="Log out" />
              </div>
              <span className="cenetrtext bottomtext ml-0">
                {this.state.openMore ? "Less" : "More"}
              </span>
            </li>
            {/* {this.state.openMore && ( */}
            {/* // </ul> */}
            {/* <li className="flexcolumn"  >
                            <img src={logout} className="widthicon" alt="Log out" onClick={this.userLogout} />
                            <span className="cenetrtext bottomtext">Log Out</span>

                        </li> */}

            {/* <li className="flexcolumn" onClick={() => this.locationButton()}>
                                <img src={locationImg} className="widthicon" data-target="#location1" data-toggle="modal" alt="Location" />
                                <span className="cenetrtext bottomtext">Location</span>

                            </li> */}
          </ul>
        </div>
        <AgendaDialog open={this.state.openAgenda} onClose={this.closeAgenda} />

        <GameList
          open={this.state.gameModalIsOpen}
          onClose={this.closeGameModal}
          //   changeProjectId={this.changeProjectId}
          //   expoName={this.props.expoName}
          //   gameData={this.props.bottomMenu}
        />
      </div>
    );
  }
}
