/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import $ from 'jquery';
import "./chatpanel.css";
import SendImg from "../images/send.png";
import { generateNewUserId, loadAttendeeMessages, loadMessages, loadModeratorMessages, saveMessageToDatabaseforModerator } from '../functions/features/chat/chat-functions';
import chaticon from '../images/chat.png';

export default class ChatPanel extends Component {

    constructor(props) {

        super(props);
        this.state = {
            roomName: "",
        }
    }
    componentWillReceiveProps() {
        this.setState({
            roomName: this.props.roomName
        })
    }
    componentDidMount() {

        // if (localStorage.getItem('email') && localStorage.getItem('uid')) {
        //     console.warn('welcome')
        // } else {
        //     window.location.href = '/'
        // }
        this.setState({
            roomName: this.props.roomName
        })
        loadMessages(this.props.roomName);
        $(function () {
            var arrow = $(".chat-head img");

            arrow.on("click", function () {
                var src = arrow.attr("src");

                $(".chat-body").slideToggle("fast");
                if (
                    src ===
                    "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
                ) {
                    arrow.attr(
                        "src",
                        "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                    );
                } else {
                    arrow.attr(
                        "src",
                        "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
                    );
                }
            });
        });
    }

    sendMessage = (e) => {
        e.preventDefault();
        console.log(this.props.roomName)
        let msg = document.getElementById("message-txt").value;
        console.log('typed msg', msg)
        if (msg !== "") {
            // console.warn('Sending', msg);
            saveMessageToDatabaseforModerator(msg, this.props.roomName);
        } else {
            console.warn("Button should be disabled now");
        }
    }
    openchat = () => {
        console.log("in click event")
        document.getElementById("webinarchatbox").classList.toggle("lefttorightchat");
        document.getElementById("message-form").classList.toggle("bottomtotopchat");
        document.getElementById("myScrollingChat").classList.toggle("ishiddenchat");
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.remove("chaticonposition2");
        }
    };

    toggleChatBox =() => {
        document.getElementById("webinarchatbox").classList.toggle("lefttorightchat");
    }


    render() {
        const { roomName } = this.props;

        return (
            <div>
                <div className="chaticonposition mobdisplay" id="Chat" onClick={this.toggleChatBox}>
                    <img alt="" src={chaticon} className="widthchaticon"></img>
                </div>
                <div className="arrow-container comdisplay" onClick={this.toggleChatBox}>
                    <img
                        src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                        alt="Expand/Collapse"
                        className="sideBarArrow"
                    />
                </div>
                <div className="bottomchatboxvertical " id="webinarchatbox">

                    {/* <div className="bottomchatbox" id="webinarchatbox" style={{ position: this.props.position ? this.props.position : '', bottom: this.props.bottom ? this.props.bottom : '', right: this.props.right ? this.props.right : '' }}> */}
                    <div className="chat-head comdisplay">
                        <h2>Ask Your Question</h2>
                    </div>
                    <div className="chat-body scrolltype" id="myScrollingChat">
                        <div className="flexform  " id="messages"></div>
                        <form
                            id="message-form"
                            onSubmit={this.sendMessage}
                            className="chat-textnormal"

                        >
                            <input
                                type="text"
                                className="message-editbox"
                                id="message-txt"
                                placeholder="Enter Message..."
                                autoComplete="off"
                            />
                            <button type="submit" className="sendarrow">
                                <img className="send-arrow-img" src={SendImg} alt="Send" />
                            </button>
                        </form>
                    </div>
                    {/* </div> */}
                </div>

            </div>
        )
    }
}
