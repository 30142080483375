/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Grid, InputLabel, Typography, Dialog, Snackbar, FormControl, Select, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  BootstrapInput,
  CssTextField,
} from "../MelzoLibrary/styledMaterialComponent";
import "./certificate.css";
import certificate from "./certificate.jpg";
import loginFirebase from "../functions/features/login/login-firebase";
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMore from "@material-ui/icons/ExpandMore";
import './certificate.css'
// import firebaseApp from "../../firebase";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Certificate({ isOpen, onClose }) {
  const [fName, setFName] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [eventName, setEventName] = useState("Select Event Name");
  const [isLoader, setIsLoader] = useState(false)
  const [showAlert, setShowAlert] = useState({
    isOpen: false,
    text: ''
  });

  const eventList = ["International Science Film Festival Of India 2021",
    "Science Literature Festival",
    "Engineering Students Festival",
    "Science Village Festival",
    "Traditional Crafts and Artisans Festival",
    "Games and Toys",
    "Global Indian Scientists & Technocrats Fest",
    "Eco Fest",
    "New Age Technology Show",
    "National Social Organisation & Institutions Meet"
  ]

  const image = new Image();
  image.src = certificate;
  image.onload = function () {
    drawImage();
  };

  useEffect(() => {
    if (localStorage.getItem("email")) {

      const db = loginFirebase.firestore();
      const userCollection = db.collection("users");
      let ExistingUserQuery = new Promise((resolve, reject) => {
        userCollection
          // .where("expo", "==", expoName)
          .where("parti_primary_email", "==", localStorage.getItem("email"))
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // console.log(doc.id, " => ", doc.data());
              let myData = doc.data();
              myData.id = doc.id;
              resolve(myData);
            });
            resolve("Not Found");
          })
          .catch(function (error) {
            reject(error);
            // this.setState({
            //   alertDialog: {
            //     isOpen: true,
            //     title: `${error}`,
            //   }
            // })
            console.log(error);
          });
      });
      ExistingUserQuery.then((user) => {
        if (user === "Not Found") {
          // this.setState({
          //   alertDialog: {
          //     isOpen: true,
          //     title: "User details not found",
          //   }
          // })
          alert("User details not found");
          onClose();
        } else {
          let name = user.parti_honor ? (user.parti_honor + " " + user.parti_name) : user.parti_name;
          setFName(name);
          let institute = user.parti_institute_name ? user.parti_institute_name : "-";
          setInstituteName(institute)
        }

      })
    } else {
      alert("something went wrong")
      onClose()
    }
  }, []);

  const drawImage = () => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    // ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    ctx.font = "bold 16px Times New Roman";
    ctx.fillStyle = "black";
    ctx.fillText(fName, 300, 400);
    // let { width } = ctx.measureText(fName + "     " + instituteName);
    // ctx.fillStyle = "#29e";
    // ctx.fillRect(40, 185, width, 2);
    // ctx.fillStyle = "red";
    ctx.font = "bold 16px Times New Roman";
    ctx.fillText(instituteName === "-" ? "                                                                  " + instituteName + " NA - " + "                                                            " : instituteName, 90, 430);
    ctx.fillText(eventName, 295, 462);

  };

  useEffect(() => {
    if (document.getElementById("canvas")) {
      drawImage()
    }
  }, [fName, instituteName])

  const generateCertificate = () => {
    setIsLoader(true)
    const canvas = document.getElementById("canvas");

    canvas.toBlob(function (blob) {
      let myPromise = new Promise((resolve, reject) => {
        const storageUrl = loginFirebase.storage(
          "gs://iisf-goa.appspot.com"
        );
        const storageRef = storageUrl.ref();
        const uploadTask = storageRef
          .child("certificate")
          .child(instituteName)
          .child(fName)
          .put(blob);
        uploadTask.on(
          "state_changed",
          (snapShot) => { },
          (err) => {
            console.log(err);
            reject(err);
          },
          () => {
            loginFirebase
              .storage("gs://iisf-goa.appspot.com")
              .ref()
              .child("certificate")
              .child(instituteName)
              .child(fName)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                resolve(fireBaseUrl);
              })
              .catch((err) => {
                console.log("error caught", err);
              });
          }
        );
      });
      myPromise
        .then((url) => {
          // console.log(url);
          loginFirebase
            .firestore()
            .collection("certificate")
            .add({
              name: fName,
              institute: instituteName,
              eventName: eventName,
              certificate: url,
              createdAt: new Date().getTime(),
            })
            .then(() => {
              // console.log(doc);
              setShowAlert({
                isOpen: true,
                text: 'Certificate Generated Successfully'
              })

              let a = document.createElement("a");
              a.href = canvas.toDataURL("image/jpg");
              a.download = `Certificate - ${fName}`;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              a.remove();
              setIsLoader(false)

            })
            .catch((err) => {
              setIsLoader(false)
              console.error(err);
            });
        })
        .catch((err) => {
          setIsLoader(false)
          console.log("error caught", err);
        });
    }, "image/png");
  };

  return (
    <Dialog
      // fullWidth
      fullWidth={true}
      maxWidth={"lg"}
      // onClose={onClose}
      // aria-labelledby="customized-dialog-title"
      open={isOpen}
      PaperProps={{
        style: {
          overflowX: "hidden",
          // backgroundColor: "transparent",
          backdropFilter: "blur(12px)",
          boxShadow: "1px 3px 15px #03030373",
        },
      }}
    >
      <div className="container">
        <div className="d-flex justify-content-between py-3">
          <Typography
            variant="h6"
            style={{
              marginTop: "5px",
              fontFamily: ["Poppins", "sans-serif"].join(","),
              color: "#707070",
              fontWeight: 500,
              fontSize: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              textTransform: 'uppercase'
            }}
          >
            Welcome to certificate Generator
          </Typography>
          <button
            className="download-certificate p-2 m-2 d-flex"
            style={{  borderRadius: "50%", width: "40px", height: "40px", justifyContent: "center", alignItems: "center", fontSize: "larger", fontWeight: "600" }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        {/* <Typography
          variant="caption"
          sx={{
            mt: "5px",
            fontFamily: "Montserrat, sans-serif",
            color: "red",
            fontWeight: 500,
            fontSize: "0.8rem",
          }}
        >
          Generate a certificate by entering name and eventName
        </Typography> */}
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {/* <Grid item xs={0} md={3} lg={3}>
             <InputLabel
              shrink
              htmlFor="fName"
              style={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: "16px",
                color: "#707070",
                fontWeight: 500,
                letterSpacing: "normal",
              }}
            >
              User Name
            </InputLabel>
            <CssTextField
              id="fName"
              name="fName"
              variant="outlined"
              value={fName}
              onChange={(e) => {
                setFName(e.target.value);
                drawImage();
              }}
              // disabled={true}
              fullWidth
              InputProps={{ disableUnderline: true }}
            /> 
          </Grid> */}
          <Grid item xs={6} md={3} lg={3}>
            <InputLabel
              shrink
              htmlFor="instituteName"
              style={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: "16px",
                color: "#707070",
                fontWeight: 500,
                letterSpacing: "normal",
              }}
            >
              Institute Name
            </InputLabel>
            <CssTextField
              id="instituteName"
              name="instituteName"
              variant="outlined"
              value={instituteName}
              onChange={(e) => {
                setInstituteName(e.target.value);
                drawImage();
              }}
              // disabled={true}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <InputLabel
              shrink
              htmlFor="eventName"
              style={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: "16px",
                color: "#707070",
                fontWeight: 500,
                letterSpacing: "normal",
              }}
            >
              Event Name
            </InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="classSelection"
                id="event"
                fullWidth
                defaultValue="Select Event Name"
                onChange={(e) => {
                  setEventName(e.target.value);
                }}
                value={eventName}
                input={<BootstrapInput />}
                IconComponent={ExpandMore}
              >
                <MenuItem key="-1" value="Select Event Name">
                  <em>Select Event Name</em>
                </MenuItem>
                {eventList.map((event, index) => (
                  <MenuItem key={index} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6} xs={12} className="d-flex justify-content-end align-items-end">
            <button
              className="download-certificate p-2 m-2 d-flex"
              disabled={isLoader}
              style={isLoader ? { background: "#ed9121" } : {}}
              onClick={() => {
                if (instituteName === "" || instituteName === "-") {
                  alert("Please Enter Institute Name")
                } else if (eventName === "" || eventName === "Select Event Name") {
                  alert("Please Select Event Name")
                } else {
                  generateCertificate()
                }
              }}
            >
              Download Certificate
              {isLoader && <div className="loader" id="btn-loader" />}
            </button>
            {/* <Button
              variant="contained"
              
              sx={{ marginLeft: 2 }}
            >
              <CardGiftcardIcon />
              
            </Button> */}
            <a href="#" id="download-btn" style={{ display: "none" }}></a>
          </Grid>
          <Grid item md={12} xs={12} className="d-flex justify-content-center m-3">
            <canvas id="canvas" height="695px" width="978px"></canvas>
          </Grid>
        </Grid>
      </div>
      <Snackbar open={showAlert.isOpen} autoHideDuration={4000} onClose={() => {
        setShowAlert({
          isOpen: false,
          text: ''
        })
      }}>
        <Alert onClose={() => {
          setShowAlert({
            isOpen: false,
            text: ''
          })
        }} severity="success">
          {showAlert.text}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default Certificate;
