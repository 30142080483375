/* eslint-disable no-undef */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  InputAdornment,
  SvgIcon,
  Typography,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import facultyImage from "./pages/images/modelimage.png";
import { makeStyles } from "@material-ui/styles";
import PortraitModeWarning from "./pages/commone/portrainModeWarning";
import SearchIcon from "@material-ui/icons/Search";
import SpeakerArry from "./agendaData";

const roomName = "webinar-room";
const expoName = "goa-virtual-meet";
const sceneName = "webinar-room";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#333333",
      backgroundColor: "#e6e6e6",
    },
    "& .MuiInput-underline:after": {
      border: "none",
      backgroundColor: "#e6e6e6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        boxShadow: "inset 0 3px 6px #ffffff6c, 0 3px 6px #ffffff6c",
      },
      "&:hover fieldset": {
        border: "none",
        boxShadow: "inset 0 3px 6px #03030373, 0 3px 6px #03030373",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        boxShadow: "inset 0 3px 6px #4d4d4d, 0 3px 6px #4d4d4d",
      },
    },
  },
})(TextField);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontSize: "1.6rem" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ top: "14px", color: "black" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backdropFilter: "blur(60px)",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
  paperClass: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
}))(MuiDialogContent);

export class AgendaDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      myArray: [],
      expanded: "panel1",
      filterArray: [],
    };
  }

  componentDidMount() {
    // updateLastSceneEndTime(roomName);
    console.log("myArray", SpeakerArry);
    this.setState(
      {
        myArray: SpeakerArry,
        // .sort((a,b)=>{
        //   return new Date(b.date) - new Date(a.date);
        // }),
      },
      () => {
        if (document.getElementById("camera")) {
          if (document.getElementById("camera").hasAttribute("c-zoom-in-out")) {
            document.getElementById("camera").removeAttribute("c-zoom-in-out");
          } else {
            document.getElementById("camera").setAttribute("c-zoom-in-out", "");
          }
        }
      }
    );
  }
  componentDidUpdate() {
    // if (this.state.id !== this.props.roomId) {
    //   let myNewArray = this.state.allRooms.filter(
    //     (obj) => obj.roomId === this.props.roomId
    //   );
    //   this.setState(
    //     {
    //       myArray: myNewArray[0].webinarHall,
    //       id: this.props.roomId,
    //     },
    //     () => {
    //       console.log("filter Data :: ", this.state.myArray);
    //     }
    //   );
    // }
  }

  gameOpen = (name) => {
    this.props.onClose();
    window.location.href = `${name}`;
  };

  setSearch = (e) => {
    try {
      if (e.target.value.length > 0) {
        const tempData = this.filterByValue(SpeakerArry, e.target.value);
        // console.log("filter data", tempData);
        this.setState(
          {
            myArray: tempData,
          },
          () => {
            // console.log(`filterArray`, myArray)
          }
        );
      } else {
        this.setState(
          {
            myArray: SpeakerArry,
          },
          () => {
            // console.log(`filterArray`, myArray)
          }
        );
      }
    } catch (err) {
      console.log("setSearch", err);
    }
  };

  filterByValue = (array, value) => {
    let result = [];

    array.filter((data) =>
      data.speakers.map((find, index) => {
        if (find.speakersName.toLowerCase().includes(value.toLowerCase())) {
          result.push({
            date: data.date,
            time: data.time,
            eventName: data.eventName,
            topic: data.topic,
            speakers: [
              {
                speakersName: find.speakersName,
                speakersPhoto: find.speakersPhoto,
              },
            ],
          });
          // array.filter((data) =>
          //   data.speakers.filter((data1) => {
          //     console.log('data1.speakerName.includes(value)', data1.speakerName.includes(value))
          //     if(data1.speakerName.includes(value)){

          //     }
          //     return (
          //       data1.speakerName.includes(value)
          //     )
          //   }
          //   ));
        } else if (index === array.length - 1) {
        }
      })
    );

    return result;
  };

  onHandleChangeAccordian = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  closeDialog = () => {
    this.props.onClose();
    if (document.getElementById("camera"))
      document.getElementById("camera").setAttribute("c-zoom-in-out", "");
  };

  render() {
    const { open, roomName, roomId, styles } = this.props;
    return (
      <div>
        <PortraitModeWarning />
        <Dialog
          fullWidth
          maxWidth={"lg"}
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={open}
          PaperProps={{
            style: {
              overflowX: "hidden",
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          className="agenda-dialoge"
        >
          <Grid
            container
            spacing={1}
            style={{
              boxShadow: "0 3px 6px #4d4d4d99",
              borderRadius: "10px",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Grid item xs={12} md={2}>
              <DialogTitle
                onClose={this.props.onClose}
                id="customized-dialog-title"
              >
                Agenda
              </DialogTitle>
            </Grid>

            {/* <Grid item xs={12} md={5}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="selectEvent"></InputLabel>
                <Select
                  labelId="selectEvent"
                  id="selectEvent"
                  placeholder="Select Event, Name or Topic"
                  value="-1"
                >
                  <MenuItem value="-1" disabled><em>Select Event, Name or Topic</em></MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">1</MenuItem>
                  <MenuItem value="3">1</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CssTextField
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder={"Search"}
                onChange={(e) => this.setSearch(e)}
              />
            </Grid>
          </Grid>
          <DialogContent className="productdialogheight">
            <div className="">
              {this.state.myArray.map((room, index1) => (
                <React.Fragment key={index1}>
                  <div id="tab-1" className="schedule-table tab-current">
                    <div>{/* <span>{room.totalEvents}</span> */}</div>
                    <div>{/* <span>{room.totalEvents}</span> */}</div>
                    <div>
                      {/* <span>{rooms.roomNo}</span> */}
                      <div className="row wow flipInX">
                        <div
                          className="col-lg-2 col-sm-3"
                          style={{ paddingRight: "0px", paddingLeft: "0px" }}
                        >
                          <div className="schedule-table__event" style={{ marginLeft: "25px" }}>
                            <h3>{room.date} </h3>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-3">
                          <div className="schedule-table__time">
                            <h3>{room.time}</h3>
                          </div>
                          {/* room.time */}
                        </div>
                        <div className="col-lg-2 col-sm-3">
                          <div className="schedule-table__time">
                            <h3>{room.eventName}</h3>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                          <div className="schedule-table__event">
                            <h3>{room.topic} </h3>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <div className="schedule-table__speaker">
                            <div className="schedule-table__speaker__info">
                              {room.speakers.map((speaker) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <img src={facultyImage} alt="" /> */}
                                  <div className="schedule-table__speaker__name">
                                    <p>{speaker.speakersName}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {/* // )} */}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AgendaDialog;
