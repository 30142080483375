import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import analyticsFirebase from '../functions/features/analytics-firebase-app'
import { analyticsFunction, updateEndTime, updateLastSceneEndTime } from '../functions/helpers/analytics-function'
import playImg from '../images/medexIcon/play-med.png';
import pauseImg from '../images/medexIcon/pause-med.png';
import PortraitModeWarning from '../commone/portrainModeWarning';
// import videoPosterImg from '../images/videoPoster.png';

import Header from '../header/header'
import WebinarRoomPopup from './webinar-room'
// const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/medexvideo.mp4'
const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/sample-welcome-video.mp4'
const videoPosterImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/sample-welcome-video-poster.png'
const webinarHallMain = 'https://angles-expo.firebaseapp.com/dekho/6197952122086c00199c5a73/5/novr/none'
const expoName = 'goa-virtual-meet';
const roomName = 'webinar-hall'
const sceneName = 'webinar-hall'
export default class WebinarHallMain2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        roomPopupOpen : false,
        roomName : "",
        roomId : ''
        }
    }

    componentDidMount(){

        if( localStorage.getItem('email')  && localStorage.getItem('uid') ){
            console.warn('welcome')
        } else{
            window.location.href = '/'
        }

        localStorage.setItem('Location', 'webinar-hall')
        window.addEventListener("message", this.projectButtonClick, false);

        updateLastSceneEndTime(roomName);
        // document.getElementById("webinarHall").classList.add("activeflexcolumn")
    }
    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }


  closeGameModal = () => {
    this.setState({ roomPopupOpen: false });
  };

    projectButtonClick = (e) => {
        console.warn('called on wabinarrhall arrow', e.data);
        if (e.data.isImageClicked) {

            // if (e.data.mslKey ==='expo.virtualpropexpo2020.com/webinar6') {
            //     window.location.href = '/GWR_Room'
            // }
            // else 
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar6') {
                window.location.href = '/webinar-hall15'
            }
            else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar7') {
                // window.location.href = '/webinar-hall8'
                this.setState({
                    roomPopupOpen : true ,
                    roomName : "Global Indian scientists & technocrats meet",
                    roomId : 5
                })
            }
            else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar8') {
                // window.location.href = '/webinar-hall9'
                this.setState({
                    roomPopupOpen : true ,
                    roomName : "ECO fest",
                    roomId : 6
                })
            }
            else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar9') {
                window.location.href = '/webinar-hall22'
            }
            else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar10') {
                // window.location.href = '/webinar-hall11'
                this.setState({
                    roomPopupOpen : true ,
                    roomName : "National social organisation & institutions meet",
                    roomId : 7
                })

            } else if (e.data.mslKey === 'goa.virtualmeet.com/lobby') {
                window.location.href = '/entrance';
            }
        }
    }

    render() {
        return (
            <div>
            <PortraitModeWarning />
                <Header headerType='user' />
                <iframe id="exteriorIframe" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={webinarHallMain}>
                </iframe>
                <WebinarRoomPopup
                 open={this.state.roomPopupOpen}
                 onClose={this.closeGameModal}
                 roomName = {this.state.roomName}
                 roomId = {this.state.roomId}
                 />
                <Footer />
            </div>
        )
    }
}
