import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import Header from '../header/header'
import salesRoomImg from "../images/sales-room.png"

export class SalesRoom1 extends Component {
    render() {
        return (
            <div>
                <Header headerType='user' />
                <img alt="webinarImage" className="imageClass" src={salesRoomImg}></img>
                {/* <video
                id="liveVideo"
                src=""
                loop
                type="application/x-mpegURL"
                className="audiVideo video-js vjs-default-skin"
                crossOrigin="anonymous"
                playsInline
              /> */}
              <Footer/>
            </div>
        )
    }
}

export default SalesRoom1
