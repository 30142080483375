import AFRAME from 'aframe'


AFRAME.registerComponent('c-zoom-in-out', {
    schema: {
        minFov: {
            type: 'number',
            default: 45
        },
        maxFov: {
            type: 'number',
            default: 85
        },
        defaultFov: {
            type: 'number',
            default: 80
        }
    },
  
    init() {
        this.scaling = false;
        this.minFov = this.data.minFov;
        this.maxFov = this.data.maxFov;
        this.defaultFov = this.data.defaultFov;
  
        this.fovVal = this.defaultFov;
        this.lastScale = -1;
        this.zoomScale = 1;
        this.bindMethods();
    },
  
    play() {
        const self = this;
        self.addEventListeners();
    },
  
    pause() {
        const self = this;
        self.removeEventListeners();
    },
  
    touchStart(e) {
        if (e.touches.length === 2) {
            this.scaling = true;
            this.lastScale = -1;
        }
    },
  
    touchMove(e) {
        if (!this.scaling) return;
  
        const dist = Math.sqrt(
            (e.touches[0].pageX - e.touches[1].pageX) * (e.touches[0].pageX - e.touches[1].pageX) +
            (e.touches[0].pageY - e.touches[1].pageY) * (e.touches[0].pageY - e.touches[1].pageY)
        );
  
        if (this.lastScale === -1) {
            this.lastScale = dist;
        } else if (dist < this.lastScale && this.maxFov > this.fovVal) {
            this.fovVal += this.zoomScale;
        } else if (dist > this.lastScale && this.minFov < this.fovVal) {
            this.fovVal -= this.zoomScale;
        }
  
        this.el.components.camera.camera.fov = this.fovVal;
        this.el.components.camera.camera.updateProjectionMatrix();
    },
    onWheel(e) {
        const delta = Math.sign(e.wheelDelta);
        // const dist = Math.sqrt(
        //     (e.deltaX) * (e.deltaX) +
        //     (e.deltaY) * (e.deltaY)
        // );
        const dist = this.lastScale + delta
        if (this.lastScale === -1) {
            this.lastScale = dist;
        } else if (dist < this.lastScale && this.maxFov > this.fovVal) {
            this.fovVal += this.zoomScale;
        } else if (dist > this.lastScale && this.minFov < this.fovVal) {
            this.fovVal -= this.zoomScale;
        }
  
        this.el.components.camera.camera.fov = this.fovVal;
        this.el.components.camera.camera.updateProjectionMatrix();
    },
  
    touchEnd() {
        this.scaling = false;
    },
  
    bindMethods() {
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.onWheel = this.onWheel.bind(this);
    },
  
    addEventListeners() {
        document.addEventListener('touchstart', this.touchStart);
        document.addEventListener('touchmove', this.touchMove);
        document.addEventListener('touchend', this.touchEnd);
        document.addEventListener('wheel', this.onWheel);
    },
  
    removeEventListeners() {
        document.removeEventListener('touchstart', this.touchStart);
        document.removeEventListener('touchmove', this.touchMove);
        document.removeEventListener('touchend', this.touchEnd);
        document.removeEventListener('wheel', this.onWheel);
    }
  });
  