import React, { Component } from 'react'
import VideoInDialog from '../display-gallary/video-in-dialog';
import Footer from '../footer/footer-page'
import authFirebaseApp from '../functions/features/auth-firebase-app';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import Header from '../header/header'
import dbFirebaseApp from '../functions/features/db-firebase-app';
import PortraitModeWarning from '../commone/portrainModeWarning';
const expoName = 'goa-virtual-meet';
const roomName = 'archiveGallery'
const sceneName = 'archiveGallery'
const archiveGalleryIframe = 'https://angles-expo.firebaseapp.com/dekho/6197952122086c00199c5a73/6/novr/none'
export default class ArchiveGallary extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false
        })
        // this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {

        document.getElementById("expohall").classList.add("activeflexcolumn")

        if (localStorage.getItem('email') && localStorage.getItem('uid')) {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user archive', user)
        //         // ...
        //     }
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //         // User is signed out
        //         // ...
        //     }
        // });
        if (localStorage.getItem('email') !== '' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'archive-gallery')
        window.addEventListener("message", this.projectButtonClick, false);
        // setTimeout(() => {
        //     let iframeEl = document.getElementById('archiveGalleryProject');
        //     if (iframeEl) {
        //         const userInfo = {
        //             name: localStorage.getItem('name'),
        //             mobileno: localStorage.getItem('mobile'),
        //             email: localStorage.getItem('email'),
        //             expo: expoName
        //         }
        //         iframeEl.contentWindow.postMessage(userInfo, '*');
        //     }
        // }, 4000);
        updateLastSceneEndTime(roomName)
        // this.updateCollection()
    }

    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }


    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }



    projectButtonClick = (e) => {
        console.log('called on lobby arrow', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive1') {
                window.location.href = '/archive1'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive2') {
                window.location.href = '/archive2'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive3') {
                window.location.href = '/archive3'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive4') {
                window.location.href = '/archive4'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive5') {
                window.location.href = '/archive5'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive6') {
                window.location.href = '/archive6'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive7') {
                window.location.href = '/archive7'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive8') {
                window.location.href = '/archive8'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive9') {
                window.location.href = '/archive9'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive10') {
                window.location.href = '/archive10'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive11') {
                window.location.href = '/archive11'
            }
        }
        if (e.data.isLinkClicked) {
            if (e.data.url) {
                if (e.data.url.includes('youtube.com')) {
                    this.setState({
                        videoUrl: e.data.url,
                        isDialogOpen: true,
                    })

                } else {
                    window.open(e.data.url, '_blank');
                }
                // const form = document.createElement("form");
                // form.setAttribute('method', "post");
                // form.setAttribute('target', '_blank');
                // form.setAttribute('action', e.data.url);
                // document.body.append(form);
                // form.submit();
            }
        }
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }


    render() {
        return (
            <div>
                <PortraitModeWarning />
                <Header headerType="user" />
                <iframe id="archiveGalleryProject" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={archiveGalleryIframe}>
                </iframe>
                <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                <Footer />
            </div>
        )
    }
}
