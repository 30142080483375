/* eslint-disable no-undef */
// This file is used to register material UI components with the our styles applied.

/* 
-------------------------------------------------------------
You can register new component and use it directly in any of 
the components with import 
----------------------------------------------------------------
 */

import {
    // Button,
    // Grid,
    InputBase,
    // Paper,
    // TableCell,
    TextField,
    // Autocomplete,
    // Chip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// import {
//     styled
// } from "@mui/material/styles";
// import {
//     isMobile
// } from "react-device-detect";

// Big Size Styled Button
// const CustomizedButton = styled(Button)({
//     boxShadow: "0 3px 6px #AFAFAF",
//     background: "#fff",
//     color: "#AFAFAF",
//     textTransform: "capitalize",
//     display: "flex",
//     justifyContent: "space-around",
//     alignItems: "flex-start",
//     flexDirection: "column",
//     padding: "16px",
//     width: "300px",
//     height: "250px",
//     borderRadius: "18px",
//     transition: "all 0.5s linear",
//     margin: 0,
//     "&:hover": {
//         background: "#fff",
//         boxShadow: "0 3px 6px #1CC9BE",
//     },
// });

//   Styled Color button
// const ColorButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#36CCA5",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     textTransform: "capitalize",
//     fontWeight: 600,
//     justifyContent: "space-between",
//     height: "33px",
//     borderRadius: "18px",
//     "&:hover": {
//         backgroundColor: "#36CCA5",
//     },
// }));

// Styled Custom Color button
// const CustomColorButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#36CCA5",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     textTransform: "capitalize",
//     fontWeight: 600,
//     justifyContent: "space-between",
//     height: "33px",
//     borderRadius: "18px",
//     position: "absolute",
//     bottom: "10px",
//     right: "8px",
//     "&:hover": {
//         backgroundColor: "#36CCA5",
//     },
// }));

// to style add options button
// const AddButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#36CCA5",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     textTransform: "capitalize",
//     fontWeight: 600,
//     justifyContent: "space-evenly",
//     width: "100%",
//     borderRadius: 18,
//     "&:hover": {
//         backgroundColor: "#36CCA55D",
//         border: "none",
//         outline: "none",
//         color: "#fff",
//     },
// }));

// // to style delete options button
// const DeleteButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#f50057",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     textTransform: "capitalize",
//     fontWeight: 600,
//     justifyContent: "space-evenly",
//     width: "100%",
//     borderRadius: 18,
//     "&:hover": {
//         backgroundColor: "#f500576a",
//         border: "none",
//         outline: "none",
//         color: "#333",
//     },
// }));

// to style online exam button
// const ActivateButton = styled(Button)(({
//     theme
// }) => ({
//     "&:hover": {
//         backgroundColor: "#36CCA5",
//         border: "none",
//         outline: "none",
//         color: "#fff",
//     },
// }));

//   Styled Material UI Paper component
// const Item = styled(Paper)(({
//     theme
// }) => ({
//     ...theme.typography.body2,
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//     width: "100%",
// }));

// Styled Material UI TableCell
// const StyledTableCell = styled(TableCell)(({
//     theme
// }) => ({
//     borderBottom: "none",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     padding: "8px",
//     verticalAlign: "top",
//     "p + &": {
//         textAlign: "justify",
//         margin: "0px",
//         padding: "0px",
//     },
// }));

// to style input field
const BootstrapTextField = withStyles(({
    theme
}) => ({
    "label + &": {
        // marginTop: theme.spacing(1),
        fontFamily: ["Poppins", "sans-serif"].join(","),
        borderRadius: 18,
    },
    "& .MuiInputBase-root-MuiFilledInput-root": {
        width: "100%",
        fontFamily: ["Poppins", "sans-serif"].join(","),
        borderRadius: 18,
    },
    "& .MuiFilledInput-root": {
        borderRadius: 18,
    },
    "& .MuiInputBase-input": {
        borderRadius: 18,
        position: "relative",
        border: "none",
        fontSize: 16,
        padding: "7px 15px",
        backgroundColor: "#ffffff",
        boxShadow: "0 3px 6px #cdcdcd",
        // Use the system font instead of the default Roboto font.
        fontFamily: ["Poppins", "sans-serif"].join(","),
        width: "100%",
    },
    "& .MuiButtonBase-root-MuiMenuItem-root": {
        fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
        fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    // "& input.Mui-disabled": {
    //     color: "#afafaf",
    //     boxShadow: "none",
    //     background: "none",
    //     WebkitTextFillColor: '#afafaf',
    //     '-webkit-text-fill-color': '#afafaf',
    // },
    // "& .MuiInput-input.Mui-disabled": {
    //     color: "#afafaf",
    //     boxShadow: "none",
    //     background: "none",
    //     WebkitTextFillColor: '#afafaf',
    //     '-webkit-text-fill-color': '#afafaf',
    // }
}))(TextField);

// Styled Select Input
const BootstrapInput = withStyles(({
    theme
}) => ({
    // root: {
    //     "& .MuiFormLabel-root-MuiInputLabel-root": {
    //       fontFamily: ["Montserrat", "sans-serif"].join(","),
    //     },
    //     "& .MuiInputLabel-shrink": {
    //       fontFamily: ["Montserrat", "sans-serif"].join(","),
    //     },
    //   },
    // "label + &": {
    //     fontFamily: ["Montserrat", "sans-serif"].join(","),
    //     borderRadius: 18,
    // },
    // "& .MuiInputBase-root-MuiFilledInput-root": {
    //     width: "100%",
    //     fontFamily: ["Montserrat", "sans-serif"].join(","),
    //     borderRadius: 18,
    // },
    // "& .MuiFormControl-root": {
    //     borderRadius: 18,
    //     boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px'
    // },
    // "& .MuiInputBase-input": {
    //     borderRadius: 18,
    //     position: "relative",
    //     border: "none",
    //     fontSize: 16,
    //     padding: "7px 15px",
    //     backgroundColor: "#ffffff",
    //     boxShadow: "0 3px 6px #00000029",
    //     // Use the system font instead of the default Roboto font.
    //     fontFamily: ["Montserrat", "sans-serif"].join(","),
    //     width: "100%",
    //     "&:focus": {
    //         boxShadow: "inset 0 3px 6px #b3b3b373",
    //         borderRadius: 18,
    //     },
    // },
    // "& .MuiFormLabel-root-MuiInputLabel-root": {
    //     fontFamily: ["Montserrat", "sans-serif"].join(","),
    // },
    input: {
        borderRadius: 8,
        position: "relative",
        border: "none",
        fontSize: 16,
        padding: "9px 12px",
        backgroundColor: "#ffffff",
        boxShadow: "0 3px 6px #cdcdcd",
        // Use the system font instead of the default Roboto font.
        fontFamily: ["Poppins", "sans-serif"].join(","),
        width: "100%",
    }
}))(InputBase);

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        "& .MuiOutlinedInput-input": {
            padding: "10px",
            color: "#000",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        "& .MuiInputLabel-shrink": {
            transform: "translate(3px, 15px) scale(0.75)",
            color: "#000",
        },
        "& .MuiFormLabel-root": {
            color: "#000",
        },
        '& .MuiOutlinedInput-root': {
            boxShadow: "0 3px 6px #cdcdcd",
            borderRadius: "8px",
            color: "#000",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                border: 0,
            },
        },
    },
})(TextField);

// to style options grid
// const StyledGrid = styled(Grid)(({
//     theme
// }) => ({
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     fontWeight: 500,
//     color: "#707070",
//     "& p": {
//         padding: "0",
//         margin: "0 0 0 10px",
//     },
// }));

// style view paper button
// const LinkButton = styled(Button)(({
//     theme
// }) => ({
//     background: "none",
//     textDecoration: "underline",
//     textTransform: "capitalize",
//     color: "#AFAFAF",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     fontWeight: 600,
//     "&:hover": {
//         backgroundColor: "transparent",
//         color: "#1CC9BE",
//     },
// }));


// style view paper button
// const ReverseLinkButton = styled(Button)(({
//     theme
// }) => ({
//     background: "none",
//     textDecoration: "none",
//     textTransform: "capitalize",
//     color: "#AFAFAF",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     fontWeight: 600,
//     "&:hover": {
//         backgroundColor: "transparent",
//         color: "#1CC9BE",
//     },
// }));

// style view paper button
// const ResponseButton = styled(Button)(({
//     theme
// }) => ({
//     background: "none",
//     backgroundPosition: "200% 100%",
//     textDecoration: "none",
//     textTransform: "capitalize",
//     color: "#1CC9BE",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     fontWeight: 600,
//     border: "1px solid #1CC9BE",
//     borderRadius: "25px",
//     // width: "30%",
//     margin: "0 15px 15px 0",
//     "&:hover": {
//         backgroundPosition: "100% 100%",
//         background: "#1CC9BE",
//         color: "#ffffff",
//     },
// }));

// fixed arrow button with blink effect
// const FixedBlinkingButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#36CCA5",
//     borderRadius: "50%",
//     position: "fixed",
//     bottom: "100px",
//     right: "10px",
//     height: "40px",
//     minWidth: "auto",
//     // animation: "blink 0.7s infinite alternate",
//     "&:hover": {
//         backgroundColor: "#36CCA5",
//         color: "#ffffff",
//     },
// }));

// style button of stepper
// const StepperButton = styled(Button)(({
//     theme
// }) => ({
//     color: "#ffffff",
//     backgroundColor: "#36CCA5",
//     borderRadius: 18,
//     textTransform: "capitalize",
//     fontFamily: ["Montserrat", "sans-serif"].join(","),
//     fontWeight: 600,
//     padding: "5px 40px",
//     "&:hover": {
//         backgroundColor: "#36CCA5",
//         color: "#ffffff",
//     },
// }));

// const StyledAutocomplete = styled(Autocomplete)(({
//     theme
// }) => ({
//     "& .MuiInputBase-root": {
//         fontFamily: ["Montserrat", "sans-serif"].join(","),
//         fontWeight: 600,
//         backgroundColor: "#fff",
//         padding: "7px 10px",
//         boxShadow: "0 3px 6px #00000029",
//         borderRadius: 18,
//         marginTop: "10px",
//     },
//     "& .MuiInput-root": {
//         '&:after': {
//             content: '""',
//             borderBottom: 'none',
//         }
//     },
//     "& .MuiInput-input": {
//         padding: 0
//     },
//     height: 24,
//     position: "relative",
//     zIndex: 0,
//     "& .MuiChip-root": {
//         backgroundColor: "#1CC9BE",
//         fontFamily: ["Montserrat", "sans-serif"].join(","),
//         fontWeight: 600,
//     },
//     "& .MuiChip-label": {
//         color: "#fff"
//     },
//     "& .MuiChip-deleteIcon": {
//         color: "#fff !important"
//     },
//     "&:after": {
//         content: '""',
//         right: 10,
//         top: 6,
//         height: 12,
//         width: 12,
//         position: "absolute",
//         backgroundColor: "white",
//         zIndex: -1
//     }

// }));

// style login page textfields
// const RedditTextField = styled(TextField)(({
//     theme
// }) => ({
//     "& .MuiFilledInput-root": {
//         border: "none",
//         overflow: "hidden",
//         borderRadius: 18,
//         backgroundColor: "#ffffff",
//         boxShadow: "0 3px 6px #00000029",
//         transition: theme.transitions.create([
//             "border-color",
//             "background-color",
//             "box-shadow",
//         ]),
//         color: "#afafaf",
//         fontFamily: ["Montserrat", "sans-serif"].join(","),
//         fontWeight: 600,
//         "&:hover": {
//             backgroundColor: "transparent",
//         },
//         "&.Mui-focused": {
//             backgroundColor: "#ffffff",
//             boxShadow: "0 3px 6px #4d4d4d73",
//         },
//     },
//     "& .MuiFilledInput-input": {
//         padding: "15px",
//         height: "1em",
//     },
// }));

// const StyledChip = styled(Chip)(({ bgcolor }) => ({
//     color: 'white',
//     backgroundColor: "#1CC9BE",
// }));

export {
    // CustomizedButton,
    BootstrapInput,
    // ColorButton,
    // AddButton,
    // DeleteButton,
    // Item,
    // StyledTableCell,
    BootstrapTextField,
    CssTextField,
    // StyledGrid,
    // LinkButton,
    // FixedBlinkingButton,
    // StyledAutocomplete,
    // RedditTextField,
    // ActivateButton,
    // ResponseButton,
    // StyledChip,
    // StepperButton,
    // ReverseLinkButton,
    // CustomColorButton,
};