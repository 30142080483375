/* eslint-disable */

import axios from "axios";

let api;
let moderatorJWT =
  "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtYTJlZjE2NGM0MDZlNGMwMmIyOTkxZTE2Yjg0M2M5OWQvZmRkYTkyIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsImV4cCI6MTcyMjQ2ODAzMywibmJmIjoxNjIyNDYwODI4LCJpc3MiOiJjaGF0Iiwicm9vbSI6IioiLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtYTJlZjE2NGM0MDZlNGMwMmIyOTkxZTE2Yjg0M2M5OWQiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJtb2RlcmF0b3IiOnRydWUsIm5hbWUiOiIiLCJpZCI6ImF1dGgwfDYwNTFkNjNlNTYxNDkzMDExNWE0YTZhYSIsImF2YXRhciI6IiIsImVtYWlsIjoiIn19fQ.VIrzvY7zgl8fyclBbNjDDSeiI5RXJ5F7IF7mG7lGtCp8E_zG7VrbA3Ow9YE-9SMbOZt23SV2YQ_aLiw9Y6QZcNnvk4J9aLOSfNM2v4attBDRGPCkUqzbjknOS3FDRKUr4fGP2LT8vCkp6H8ZFHaHfv3AIkrkXpaMGLvcR7YkugCGDAYCYeqHrPELwQgLwJk3tQDQiHwvCZNvh1t9UtLLHpOib5kDz3RWW4IujscIryGINcf45AN2sAckPgXB0X2-6xaFjkvYSfMuxYS8Yd9BCBhvGUE3h6Oh-HCxW3B1g_yWfH1ZmGHR3RELMN6uuQvn92zV_emZRXV4pp7H8b9UKTxhIRX7lRuFpw4wmTAZUCcDx9zDwvoLbl7ZwIaoavNXk1KpSbHxYQxMEIl_GZv7anZa2040cwDHA6Pii0EkDq-yNSxZlIMERw6N6X60zk9kU0lSVp7iha-sAoRQmwNB9rQqIBASVu3lDAPjw0sXdzADlIGQD7TaaZamiZ5wQMUkzLAPitlbL3efBpl-ikmclSLk7wjjSu60DBz-FpsYIyb2E5kfU803JJQ_MUVQUyNXj4_bLczyGHtmK8Eb7dTs-Jb9J_ceXgxKDE6-X5jImWsMvatRoMOVNu8sll0nzxXpAADiH3VVn90Oxssijhu9IJpr6IPSe-YhjjXYLpKdTcU";
let userJWT =
  "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtYTJlZjE2NGM0MDZlNGMwMmIyOTkxZTE2Yjg0M2M5OWQvZmRkYTkyIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsImV4cCI6MTcyMjQ2ODAzMywibmJmIjoxNjIyNDYwODI4LCJpc3MiOiJjaGF0Iiwicm9vbSI6IioiLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtYTJlZjE2NGM0MDZlNGMwMmIyOTkxZTE2Yjg0M2M5OWQiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJtb2RlcmF0b3IiOmZhbHNlLCJuYW1lIjoiIiwiaWQiOiJhdXRoMHw2MDUxZDYzZTU2MTQ5MzAxMTVhNGE2YWEiLCJhdmF0YXIiOiIiLCJlbWFpbCI6IiJ9fX0.LQr4JGbDFiYCUOjW0dv27nzkQwq93ysnexmirls7dpyvsdm5vuwTrP9djgD6CriIR7PLFxOWSfPsm6jsdo7YPty2cQ974Gn7UIRbarBqWnt-ntXmTUL_gx5e0djCFK6Gw86pKZR0ZcLeBz17d1wV_8fJrCbAkR2X2hDlv4rfOyzeSO3MHpYE1Cf6Z2RtqeKuo_HXg65jM1kKsNQ82OddC3ubQMU4pkbPJFnLuev3hBWfoLePyX109gzjwCMNCAgwEjxmDskrzxDGslyrradfgucIL5SLolYvxVZBMEZ1A34Rj8mRUgLQXC7iwPX9o7i9BUVD-KzLdPABMuU-20BKsWnKZIKiZS-P1Wuizo_tsCKIAAML0tno8IjoP3yiR1khr0s3bqwEfE53AF-jNJpC5QWPOyNEruQkO6N3PeZveUM8l-Pdxn3HH9MJT_d3p3cCKnYV-WZxmXMHJFjxw6N-ENH92RVaJHiC9rhE86B41q_ubf0edB8vD8FgmQvOp5cj1GJRiGqRiF3lecWCsL2dufc3DLZurR9DaIbo7bJnTyzGqzY-oxxgARhUON3D0EOYseyfpom9AApY0noibtmBPFy25zSva2Nyi2z18KCn44Dxk9LSCrhrRQSk-z7O1VJAR2nI58UCJnuJoLgnB3NEH9flt9xSAaJ0tw28AsgG1_s";
let tenant = "vpaas-magic-cookie-a2ef164c406e4c02b2991e16b843c99d";

export function StartInvestorMeeting(roomName, meetingEngCallback, isModerator = false) {
  const domain = "meetings.melzo.com";

  const nameOfUser = localStorage.getItem('email') ?
    (localStorage.getItem('email') === 'edp@rubberboard.org.in' ?
      'goa virtual meet' :
      (localStorage.getItem('name') ? localStorage.getItem('name') : "")) :
    (localStorage.getItem('name') ? localStorage.getItem('name') : "");

  const options = {
    roomName: tenant + "/" + roomName, //'vpaas-magic-cookie-88dfb7a7488f43a689e37d432fe97a85/test'
    width: "100%",
    height: "100%",
    // moderator: true,
    parentNode: document.querySelector("#meet"),
    // jwt: isModerator ? moderatorJWT : userJWT,
    userInfo: {
      email: localStorage.getItem('email') ? localStorage.getItem('email') : "",
      displayName: nameOfUser,
    },
    interfaceConfigOverwrite: {
      TOOLBAR_ALWAYS_VISIBLE: false,
      HIDE_INVITE_MORE_HEADER: true,
      SHOW_WATERMARK_FOR_GUESTS: true,
      TOOLBAR_TIMEOUT: 5000,
      INITIAL_TOOLBAR_TIMEOUT: 5000,
      SHOW_CHROME_EXTENSION_BANNER: false,
      VIDEO_QUALITY_LABEL_DISABLED: true,
      ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 5000,
      DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      HIDE_KICK_BUTTON_FOR_GUESTS: true,
      FILM_STRIP_MAX_HEIGHT: 120,
      DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
      DISABLE_FOCUS_INDICATOR: true,
      TILE_VIEW_MAX_COLUMNS: 5,
      TOOLBAR_BUTTONS: [
        "microphone",
        "camera",
        "fullscreen",
        "raisehand",
        "filmstrip",
        "desktop",
        "chat",
        "tileview",
        "hangup",
        "recording",
        "fodeviceselection",
      ]
    }
  };
  const jwtPromise = new Promise((abc, xyz) => {
    const userData = {
      userName: nameOfUser,
      userEmail: localStorage.getItem('email') ? localStorage.getItem('email') : "",
      isModerator: isModerator
    }

    axios.post('https://ptpvr.com/meetings/getJWT', userData)
      .then(res => {
        // console.log('Token Received', res.data);
        abc(res.data);
      }).catch(xyz)
  });

  jwtPromise.then(result => {
    options.jwt = result;
    api = new JitsiMeetExternalAPI(domain, options);

    api.addEventListener('videoConferenceLeft', function (leftMeetingData) {
      // console.warn("I have left meeting", leftMeetingData.roomName);
      meetingEngCallback();
    });
  })
}
