import React, { Component } from "react";
import redirectEventArea from "../images/webinar/backButton.png";

export default class RedirectEventArea2 extends Component {
  render() {
    return (
      <div className="backButton">
        <img
          alt="Back Button"
          src={redirectEventArea}
          title="Back to event area"
          onClick={() => (window.location.href = "/webinar-hall-2")}
        />
      </div>
    );
  }
}