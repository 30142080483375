import firebase from 'firebase';
let loginFirebase;
SetupFirebaseLogin();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebaseLogin() {
    var firebaseConfig = {
        apiKey: "AIzaSyAgTh8fbw_SF_YOROYpOWtdafAMVGHeB64",
        authDomain: "iisf-goa.firebaseapp.com",
        projectId: "iisf-goa",
        storageBucket: "iisf-goa.appspot.com",
        messagingSenderId: "828669456098",
        appId: "1:828669456098:web:a8f23d79ee0682e68078bb",
        databaseURL: "https://iisf-goa.firebaseio.com"
      };
    // Initialize Firebase
    loginFirebase = firebase.initializeApp(firebaseConfig , 'login');
}

export default loginFirebase;