import React, { Component } from "react";
import webinarHall2d from "../images/webinar/ECO fest.jpg";
// import "./css/main.css"
import videojs from "video.js";
import Header from "../header/header";
import Footer from "../footer/footer-page";
import ChatPanel from "../chatpanel/ChatPanel";
import {
  analyticsFunction,
  updateLastSceneEndTime,
} from "../functions/helpers/analytics-function";
import PortraitModeWarning from "../commone/portrainModeWarning";

const liveFormat = "application/x-mpegURL";
const liveVideoSrc = "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8";

const roomName = "webinar-hall27";
const expoName = "goa-virtual-meet";
const sceneName = "webinar-hall27";
class WebinarHall27 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: true,
    };
  }

  componentDidMount() {
    // if (localStorage.getItem("email") && localStorage.getItem("uid")) {
    //   console.warn("welcome");
    // } else {
    //   window.location.href = "/";
    // }
    // videojs("liveVideo").src({
    //   type: liveFormat,
    //   src: liveVideoSrc ? liveVideoSrc : "",
    // });
    // this.changeStateForLoader();
    localStorage.setItem("Location", "webinar-hall27");

    // this.updateCollection();
    analyticsFunction(roomName);
    // updateLastSceneEndTime(roomName);
  }

  changeStateForLoader = (value) => {
    videojs("liveVideo").play();

    // this.setState({isloading:value})
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        <Header headerType="user" />
        <img
          alt="webinarImage"
          className="imageClass"
          src={webinarHall2d}
        ></img>
        <iframe
          src="https://vimeo.com/event/1650647/embed"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: "5.15vh",
            height: "45vh",
            left: "32.2vw",
            width: "35.5vw",
            background: "black",
            objectFit: "fill",
            overflow: "hidden",
          }}
          title="Goa Test Event"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <ChatPanel roomName="webinar-hall27" />
        {/* <Footer /> */}
      </div>
    );
  }
}

export default WebinarHall27;
