/* eslint-disable  */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint react/prop-types: 0 */
/* eslint-disable no-param-reassign */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
// import "./filter.css";
// import axios from "axios";
// import config from "../../common/utils/base-urls";
// import NoData from "../../Products/images/NoData.png";
// import gameImage from "../../common/images/game/game.jpg";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
});




const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

let duplicateFilterData = [];
let gameToDisplay = [];
let stallData = [];
class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noDataImage: true,

      // Game
      game1: false,
      game2: false,
      game3: false,
      game4: false,
      game5: false,
      game6: false,
      game7: false,
      game8: false,
      game9: false,
      game10: false,

      DisplayGame: [],
      game: [
        {
          name: "The A-Maze Game",
          shortName: "The A-Maze Game",
          src: "https://www.addictinggames.com/embed/html5-games/24768",
          imageSrc: "https://www.addictinggames.com/sites/default/files/mazegamefix.jpg",
          description: "Puzzle Game"
        },
        {
          name: "Jumping Color",
          shortName: "Jumping Color",
          src: "https://www.addictinggames.com/embed/html5-games/24760",
          imageSrc: "https://www.addictinggames.com/sites/default/files/jumpingcolorTK.png",
          description:
            "Jumping Color is a simple action that you can play on your phone or computer. The concept of this game is so easy! The color of the circle indicates what color wall it can touch. So if it’s blue, it can touch blue. If it’s purple then it can touch purple. Easy, right? No tutorial is necessary for this instant game. Just click play, and get to playing. When you click on the circle it will bounce up. Use gravity to your advantage to get the circle to the correct wall. Once it hits the right color, the walls will change colors. If you hit the wrong color, you lose! Hey, this game is all about chances though. Play again with a goal to beat your own best score. Keep playing as you rise up the leaderboards until you are the best of the best!"
        },
        {
          name: "Path",
          shortName: "Path",
          src: "https://www.addictinggames.com/embed/html5-games/24756",
          imageSrc: "https://www.addictinggames.com/sites/default/files/pathgame.jpg",
          description:
            "The path is a free puzzle game. Which way glowing orb? There is only one path, there is only one way, and there is only one sequence of events that will unlock that path. Your job is to toggle the obstacles, move the objects, and clear the way for a series of orbs so that they can make it all the way from point a to point b. This is a puzzle game where there is only one answer and one solution to every level. You can't fake it, you can't fluke it, and you can't fathom any alternatives to the one true path. The path is the way to victory and your job isn't just to walk it but to forge it. This is a game that will test your knowledge of cause and effect, physics, timing, and obstacles. The Path is a game that will allow you to live out your dreams of finding your way through a maze without a minotaur. While the levels aren't timed we would obviously think a lot more of you if you could get them done in a certain amount of time. The lower the better. And don't make any mistakes! If you screw up just once it's game over."
        },
        {
          name: "Word Detector",
          shortName: "Word Detector",
          src: "https://www.addictinggames.com/embed/html5-games/24774",
          imageSrc: "https://www.addictinggames.com/sites/default/files/word-detector-0.jpg",
          description:
            "Word Detector is a free word search game. So, you think you can spell? Well, we dare you to try and prove it. Some word games are a stodgy affair rife with trivia and pedantic rules about grammar. Word Detector is a speed game where you are given a series of letters and scored on your ability to connect each letter to the other letters and form as many words as you possibly can in as short an amount of time as you possibly can. The game will start off easy with a common letter which can be arranged into a variety of irregularly used words but as the time ticks away you're going to be forced to really scratch your noggin as you try and make 3 words out of Y,A,C, and L. Try it. It's a lot harder than you think and it is also a lot more satisfying when you actually pull it off. Word Detector is a fabulous ride through the crazy world of spelling, grammar, words, and all the magic that comes from them"
        },
        {
          name: "Flappybird Classic",
          shortName: "Flappybird Classic",
          src: "https://www.addictinggames.com/embed/html5-games/24032",
          imageSrc: "https://www.addictinggames.com/sites/default/files/flappybirdgame.jpg",
          description:
            "Flappybird Classic is an online clicker game that looks deceivingly easy. Navigate a funny-looking orange bird through green pipes of various lengths and heights. Your character flies across the blue sky with skyscrapers far in the background. The objective of the game is to pass as many points as possible without running into or hitting any pipes. The more points you pass, the better your score. If you fall or run into a pipe, you lose the game! This is the kind of game you play over and over again.  At the end of each game session, you will receive your most recent score and best score. Click on the leaderboard to see if your score is good enough to place and be at the top! Play again to beat your score as you rise up the leaderboards and beat all your friends! Play this classic clicker game on your phone or computer for hours of addictive fun!"
        },
        {
          name: "2048 Legend",
          shortName: "2048 Legend",
          src: "https://www.addictinggames.com/embed/html5-games/24772",
          imageSrc: "https://www.addictinggames.com/sites/default/files/2048legendgame.jpg",
          description: "2048 Legend is a merging puzzle game. If you have never played a 2048 game before, then this is a great classic version of the game for you to try out. There are 3 modes for this online game: 4x4, 6x6, and 8x8. Choose the best one depending on what kind of challenge you are looking for. If you are a newbie, the objective is simple: merge numbers together to create the biggest number possible. Random 2s and 4s will be added to the game every time you make a combination. Sometimes numbers can get in your way of merging! Look at the board and think carefully before you slide a number over. It will slide all the way to the end so it may not hit the number you need it to. Even if you don’t get the largest number possible, the game ends once you fill-up the entire grid. Play again to beat your best score and to climb up the leaderboards.  "
        },
        {
          name: "Two Stunt Rivals",
          shortName: "Two Stunt Rivals",
          src: "https://www.addictinggames.com/embed/html5-games/24762",
          imageSrc: "https://www.addictinggames.com/sites/default/files/twostuntrivalsgame.jpg",
          description:
            "Two Stunt Rivals is a free racing game. Stunt rivals is a fast and free racing game that pits you against the deadliest competition around someone you know. In this game, you won't be racing against artificially intelligent robots or against an arena full of other players from around the world. No, in this game you will be racing against a friend or family member who is sitting right alongside of you. Let the trash-talking, engine revving, and racing b begin! in this game, it's not just about making it around a course, oh no, in this game you are going to be competing against the clock to pull off as many different jumps and stunts as you possibly can. The time is ticking and you have to prove your value by stunting, jumping, and flip-flopping your way onto the great big leader board in the sky. If you want to defeat your friend next to you, then you're going to have to stretch your fingers and get em clicking. This is not a game for the faint of heart or the weak of will. This is a game for people who play to win and win to live."
        },
        {
          name: "Tiny Archer",
          shortName: "Tiny Archer",
          src: "https://www.addictinggames.com/embed/html5-games/24749",
          imageSrc: "https://www.addictinggames.com/sites/default/files/tinyarchergame.jpg",
          description:
            "Tiny Archer is a free shooter game. Physics is the true master of our reality. All matter, all time, all of space must bow to the science of physics as it pulls, pushes, and grasps at us all.  In Tiny Archer you will have your chance to harness the power of physics and use it to your advantage. Weild a mighty bow and take potshots at a never-ending series of floating targets. You'll only ever get one shot per target, so, you won't be able to gauge the distance and power needed by firing off a few test arrows. In this game, it's one shot one point. You are allowed to miss but only 6 times, after that you've failed and you must start again. Your points are a currency that you can use to unlock cool new skins for your archer character and flashy new targets for them to fire at. This is a cute shoot, mobile game which will challenge you with floating targets and reward you with points. It is truly a game of kings."
        },
        {
          name: "Hidden Objects: Super Thief",
          shortName: "Hidden Objects: Super Thief",
          src: "https://www.addictinggames.com/embed/html5-games/24747",
          imageSrc: "https://www.addictinggames.com/sites/default/files/hidden-objects-super-thief1_sp.png",
          description:
            "Hidden Objects: Super Thief is a hidden objects game with adventure. It’s your lucky day! You get to be trained by the best thief in town. Except not everyone knows she’s the best of the best. Help her gain a reputation by stealing from various locations to earn more clients. Each client will provide you with a list of items that need to be stolen. It’s your job to keep track of the lists and click on all the hidden objects. Find the items as quickly as you can for bonus points. The better the score, the more likely the chance to gain 4 stars per location. As an unpaid intern, you have to work harder than anyone!"
        },
        {
          name: "Pivot Dot",
          shortName: "Pivot Dot",
          src: "https://www.addictinggames.com/embed/html5-games/23749",
          imageSrc: "https://www.addictinggames.com/sites/default/files/pivot-dot.jpg",
          description:
            "Pivot Dot is a free mobile clicker game. Pivot Dot is the height of abstract, mobile, clicker based strategy games. In this simple, minimal game, all you have to do is click it and hit the black void. The problem is, that you're a spinning cell and your direction after being clicked will be entirely dependent on which direction you're facing when clicked, and even after you click you'll immediately change direction. So, this is a game of fast clicks that must be thought about in advance. You want to slowly navigate yourself towards the black dot and do so in small steps which require you to anticip[ate the direction of the spinning dot and stay on course as you click your way to victory. Pivot Dot is a minimal game, an addictive game, and a game that will require you to push yourself to your utmost limits as you make long term strategic decisions based on short term tactical actions."
        }
      ]
    };
  }



  onClose = () => { };

  gameOpen = name => {
    window.open(`${name}`, '_blank');
    this.props.onClose();
    window.location.href = '/entrance';
  };

  render() {
    const { open } = this.props;

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={this.props.onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.onClose}
            style={{
              display: "flex",
              justifyContent: "center",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            Game Zone
          </DialogTitle>

          <DialogContent dividers className="productdialogheight" style={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
          }}
         >
            {/* {this.state.noDataImage ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={NoData} style={{ height: "270px" }} />
              </div>
            ) : ( */}
            <div className="row">
              {this.state.game.map((game, index) => (
                <React.Fragment key={index} >
                  <div

                    className="col-12 col-md-3"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <div id="filter-card" style={{ background: "transparent", borderStyle: "double", border: "4px solid beige" }}>
                      <div
                        className="card-image waves-effect waves-block waves-light"
                        onClick={() => this.gameOpen(game.src)}
                      >
                        <div className="game">
                          <img src={game.imageSrc} alt="" />
                        </div>
                      </div>
                      <div className="card-content">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            color: "wheat"
                          }}
                          className="card-title activator"
                        >
                          {game.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {/* // )} */}
          </DialogContent>
        </Dialog>
        {this.state.game1 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24768"
            scrolling="no"
          />
        )}
        {this.state.game2 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24760"
            scrolling="no"
          />
        )}
        {this.state.game3 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24756"
            scrolling="no"
          />
        )}
        {this.state.game4 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24774"
            scrolling="no"
          />
        )}
        {this.state.game5 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24032"
            scrolling="no"
          />
        )}
        {this.state.game6 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24768"
            scrolling="no"
          />
        )}
        {this.state.game7 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24760"
            scrolling="no"
          />
        )}
        {this.state.game8 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24756"
            scrolling="no"
          />
        )}
        {this.state.game9 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24774"
            scrolling="no"
          />
        )}
        {this.state.game10 && (
          <iframe
            src="https://www.addictinggames.com/embed/html5-games/24032"
            scrolling="no"
          />
        )}
      </div>
    );
  }
}

export default GameList;
