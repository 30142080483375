/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import { PAGETYPE } from '../../functions/helpers/constants';
import "bootstrap/dist/css/bootstrap.min.css";
import "./login-page.css";
import moment from "moment";
import loginPageImg from "../images/iisf-bgLogin.png"
import LandingPage from "../videoPage/landingPage";
import { analyticsFunction } from "../functions/helpers/analytics-function";
import loginFirebase from "../functions/features/login/login-firebase";
import AlertDialog from "../../AlertDialog";
import Marquee from "../Marquee";

// const expoName = "goa-virtual-meet";
const expoName = "goa-virtual-meet";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginEmail: "",
      loginPassword: "",
      isLoginUser: false,
      isLoader: false,
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: ""
      }
    };
    this.loginButton = this.loginButton.bind(this);
    this.authCheck = this.authCheck.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.handleEmailKeyPress = this.handleEmailKeyPress.bind(this);
    this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
    this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this);
    this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this);
    // this.initializeWow = this.initializeWow.bind(this);
  }


  setAlertDialog = () => {
    this.setState({
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: ""
      }
    }, () => {
      this.setState({
        isLoader: false
      })
    });
  };

  // initializeWow() {
  //     var wow = new WOW({
  //         boxClass: 'wow', // animated element css class (default is wow)
  //         animateClass: 'animated', // animation css class (default is animated)
  //         offset: 100, // distance to the element when triggering the animation (default is 0)
  //         mobile: true, // trigger animations on mobile devices (default is true)
  //         live: true, // act on asynchronously loaded content (default is true)
  //         callback: function (box) {
  //             // the callback is fired every time an animation is started
  //             // the argument that is passed in is the DOM node being animated
  //         }
  //     });

  //     wow.init();
  // }

  // componentDidMount() {
  //     authFirebaseApp.auth().onAuthStateChanged((user) => {
  //         if (user) {
  //             console.warn('user is ',user)
  //             if(localStorage.getItem('userRole') === 'speaker'){
  //                 window.location.href = '/speaker-webinar'
  //             }
  //             else if(localStorage.getItem('userRole') === 'moderator'){
  //                 window.location.href = '/moderator-webinar'
  //             }else if(localStorage.getItem('userRole')==='user'){
  //                 window.location.href = "/entrance"
  //             }
  //             else{
  //                 window.location.href = '/entrance'
  //             }
  //           // User is signed in, see docs for a list of available properties
  //           // https://firebase.google.com/docs/reference/js/firebase.User
  //           var uid = user.uid;
  //           // ...
  //         }
  //         else {
  //             console.warn('user not found')
  //           // User is signed out
  //           // ...
  //         }
  //       });
  // }

  componentDidMount() {
    if (localStorage.getItem('email') && localStorage.getItem('uid')) {
      window.location.href = '/entrance'
    } else if (localStorage.getItem('userRole') === 'speaker' && localStorage.getItem('uid') !== '') {
      window.location.href = '/speaker-webinar'
    } else if (localStorage.getItem('userRole') === 'moderator' && localStorage.getItem('uid') !== '') {
      window.location.href = '/moderator-webinar'
    }
  }

  loginButton(e) {
    e.preventDefault();
    this.setState({
      isLoader: true
    })
    var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
    if (this.state.loginEmail === "") {
      this.setState({
        alertDialog: {
          isOpen: true,
          title: "Please enter Email Id",
        }
      })
      // alert("Please enter Email Id");
    } else if (this.state.loginPassword === "") {
      this.setState({
        alertDialog: {
          isOpen: true,
          title: "Please enter password",
        }
      })
      // alert("Please enter Email Id");
    } else if (!patternEmail.test(this.state.loginEmail)) {
      this.setState({
        alertDialog: {
          isOpen: true,
          title: "Please enter valid Email Id",
        }
      })
      // alert("Please enter valid Email Id");
    } else {
      this.authCheck();
    }
  }

  handleEmailKeyPress(e) {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("login-password-txt").focus();
    }
  }

  handlePasswordKeyPress(e) {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("login-btn").click();
    }
  }

  handleLoginEmailChange(e) {
    e.preventDefault();
    this.setState({ loginEmail: e.target.value });
  }

  handleLoginPasswordChange(e) {
    e.preventDefault();
    this.setState({ loginPassword: e.target.value });
  }

  authCheck() {
    let loginEmailId = this.state.loginEmail.toLowerCase();
    const loginPassword = this.state.loginPassword;
    loginEmailId = loginEmailId.replace(/ /g, "");
    loginEmailId = loginEmailId.toLowerCase();
    // let loginData = new Promise((resolve, reject) => {
    //     authFirebaseApp.auth().signInWithEmailAndPassword(loginEmailId, loginPassword)
    //         .then(function (user) {
    //             resolve(user);
    //         }).catch(function (error) {
    //             // Handle Errors here.

    //             reject(error)
    //             // ...
    //         });

    // });
    // loginData.then(result => {
    //     console.warn('login Successful')
    //     // checkForExistingUserforLogin(loginEmailId)

    // }).catch(error => {
    //     console.error(error)
    //     var errorMessage = error.message;
    //     alert(errorMessage)
    // })
    this.getUserDetails(loginEmailId, loginPassword);
  }

  getUserDetails(emailId, secretKey) {
    const db = loginFirebase.firestore();
    const userCollection = db.collection("users");
    let ExistingUserQuery = new Promise((resolve, reject) => {
      userCollection
        // .where("expo", "==", expoName)
        .where("parti_primary_email", "==", emailId)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            console.log(doc.id, " => ", doc.data());
            let myData = doc.data();
            myData.id = doc.id;
            resolve(myData);
          });
          resolve("Not Found");
        })
        .catch(function (error) {
          reject(error);
          this.setState({
            alertDialog: {
              isOpen: true,
              title: `${error}`,
            }
          })
          console.log(error);
        });
    });
    ExistingUserQuery.then((result) => {
      if (result === "Not Found") {
        this.setState({
          alertDialog: {
            isOpen: true,
            title: "User details not found",
          }
        })
        // alert("User details not found");
        document.getElementById("login-btn").removeAttribute("disabled");
      } else {
        // commonFunction(PAGETYPE.LOBBY);

        var mydate = moment(result.parti_dob).format(
          "DDMMYYYY")
        console.log("date formate", mydate)

        if (result.parti_primary_email === emailId && mydate === secretKey) {
          localStorage.setItem("name", result.parti_name);
          // localStorage.setItem('userId', result.id);
          localStorage.setItem("country", result.parti_state);
          localStorage.setItem("uid", result.id);
          localStorage.setItem("email", result.parti_primary_email);
          localStorage.setItem("mobile", result.parti_contact_number);
          // localStorage.setItem("designation", result.designation);
          // localStorage.setItem("speciality", result.speciality);
          localStorage.setItem("roomName", "entrance");
          analyticsFunction("exterior");
          if (result.userRole) {
            localStorage.setItem("userRole", result.userRole);
          } else {
            localStorage.setItem("userRole", "user");
          }
          if (result) {
            console.warn("user is ", result);
            if (localStorage.getItem("userRole") === "speaker") {
              window.location.href = "/speaker-webinar";
            } else if (localStorage.getItem("userRole") === "moderator") {
              window.location.href = "/moderator-webinar";
            } else if (localStorage.getItem("userRole") === "user") {
              window.location.href = "/events";
              // this.setState({
              //   isLoginUser: true,
              // });
            } else {
              window.location.href = "/events";
              this.setState({
                // isLoginUser: true,
              });
            }
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // ...
          } else {
            console.warn("user not found");
            // User is signed out
            // ...
          }
        } else {
          this.setState({
            alertDialog: {
              isOpen: true,
              title: "Your password is incorrect. Please enter your registered email/password combination",
            }
          })
          // alert(
          //   "Your password is incorrect. Please enter your registered email/password combination"
          // );
        }
      }
    }).catch((error) => {
      this.setState({
        alertDialog: {
          isOpen: true,
          title: "Already existing user check error",
          subTitle: `${error}`
        }
      })
      console.error("Already existing user check error", error);
    });
  }
  goToAgenda = () => {
    document.getElementById("location1").style.display = "block";
  };
  closePopup = () => {
    document.getElementById("location1").style.display = "none";
  };

  gotoRegister = () => {
    window.location.href = "/registration"
  }


  render() {
    const { isLoginUser } = this.state;
    return (
      <div>
        {!isLoginUser && (
          <>
            {/* <div className="logoposition">
              <img src={angleslogo} alt="" className="widthlogometaild" />
            </div> */}
            <div id="content-wrapper">
              <div className="blocks-container">
                <div
                  className="block type-1 scroll-to-block"
                  data-id="register"
                >
                  {/* <div className="clip">
                    <div className="bg"></div>
                    <div className="bg-span"></div>
                  </div> */}
                  <div
                    className="container-fluid type-1-text wow flipInX "
                    data-wow-delay="0.3s"
                  >
                    {/* <Marquee /> */}
                    <div className="row">
                      <div
                        className="col-md-4 col-lg-4 col-12  type-1-center"
                        style={{ textAlign: "left", paddingLeft: 0 }}
                      >


                        <div>
                          <img src={loginPageImg} alt="" className="login-image" />
                        </div>
                      </div>

                      <div
                        id="registration-form"
                        style={{ display: "block" }}
                        className="col-md-8 col-lg-8 col-12 type-1-center"
                      >

                        
                        <div className="centered flex--form">
                          <h5 className="mb-3 colored-text">
                            Login to IISF 2021
                          </h5>


                          {/* <form onSubmit={(e) => this.loginButton(e)}> */}
                          <input
                            className="required inputclass mb-3"
                            id="login-email-txt"
                            style={{ width: "100%" }}
                            value={this.state.loginEmail}
                            onChange={this.handleLoginEmailChange}
                            onKeyUp={this.handleEmailKeyPress}
                            type="email"
                            placeholder="*Your email"
                            name="email"
                          />
                          <input
                            className="required inputclass"
                            id="login-password-txt"
                            autoComplete="false"
                            value={this.state.loginPassword}
                            style={{ width: "100%" }}
                            onKeyUp={this.handlePasswordKeyPress}
                            onChange={this.handleLoginPasswordChange}
                            type="password"
                            placeholder="*Password"
                            name="password"
                          />

                          <div>
                            <button className="spanned-btn">Your password will be ddmmyyyy. For ex (if your DOB is 26-06-1990 then your password will be 26061990)</button>
                            {/* <button className="linked-btn">Forget Password ?</button> */}
                          </div>
                          <div
                            className="submit-wraper"
                            style={{ marginTop: "20%" }}
                          >
                            <button
                              className="loginbutton"
                              style={{ marginBottom: "20px", outline: 0 }}
                              id="login-btn"
                              onClick={(event) => this.loginButton(event)}
                            >
                              <div id="login-name" style={{ display: "flex", justifyContent: "center" }}>
                                Login
                                {this.state.isLoader && (
                                  <div className="loader" id="btn-loader" />
                                )}
                              </div>
                              <div
                                className="loaderBtn"
                                id="loader-login"
                                style={{ display: "none" }}
                              ></div>
                            </button>

                            <div>
                              <button className="register-btn" onClick={this.gotoRegister}>Register Here</button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        }
        {isLoginUser && <LandingPage />}
        <AlertDialog
          alertDialog={this.state.alertDialog}
          setAlertDialog={this.setAlertDialog}
        />
      </div >
    );
  }
}
