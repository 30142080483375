import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import nurseAgendaImg from '../images/NURSES.png'
import neurologistsAgendaImg from '../images/NEUROLOGISTS.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../footer/footer-page.css';
import PortraitModeWarning from '../commone/portrainModeWarning';
export default class AgendaPage extends Component {



    render() {
        return (
            <div>

                <PortraitModeWarning />
                <div className="modal" id="location1" >
                    <div className="modal-dialog modal-dialog-centered modal-lg" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title green-text" id="staticBackdropLabel">Agenda</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={this.closePopup}>&times;</button>
                            </div>
                            <div className="agendaButton">
                                <button className="btnBlueGreen active" id='agendaNurseBtn' onClick={this.nurseAgenda}>Nurse</button>
                                <button className="btnBlueGreen margin" id='agendaNeurologistBtn' onClick={this.neurologistAgenda}>Neurologist</button>
                            </div>

                            <div className="agendaImage" id='nurseAgenda'>
                                <img src={nurseAgendaImg} style={{ width: '100%' }} />

                            </div>
                            <div className="agendaImage" id='neurologistAgenda' style={{ display: 'none' }}>
                                <img src={neurologistsAgendaImg} style={{ width: '100%' }} />

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
