import { createBrowserHistory } from "history";
import React, { Component } from "react";
import "./main.css";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import AgendaPage from "../agenda/agenda-page";
import ArchiveGallary from "../archive-gallary/archive-gallary-page";
import ArchiveGallary1 from "../archive-gallary/archive-gallary-page1";
import ArchiveGallary10 from "../archive-gallary/archive-gallary-page10";
import ArchiveGallary11 from "../archive-gallary/archive-gallary-page11";
import ArchiveGallary2 from "../archive-gallary/archive-gallary-page2";
import ArchiveGallary3 from "../archive-gallary/archive-gallary-page3";
import ArchiveGallary4 from "../archive-gallary/archive-gallary-page4";
import ArchiveGallary5 from "../archive-gallary/archive-gallary-page5";
import ArchiveGallary6 from "../archive-gallary/archive-gallary-page6";
import ArchiveGallary7 from "../archive-gallary/archive-gallary-page7";
import ArchiveGallary8 from "../archive-gallary/archive-gallary-page8";
import ArchiveGallary9 from "../archive-gallary/archive-gallary-page9";
import BreakTimeZone from "../break-time-zone/break-time-zone-page";
// import BreakoutConferance from '../breakout/breakout-conferance'
// import BreakoutPage from '../breakout/breakout-page'
// import BreakoutPage2 from '../breakout/breakout-page-2'
// import BreakoutPage3 from '../breakout/breakout-page-3'
// import BreakoutPage4 from '../breakout/breakout-page-4'
// import BreakoutPage5 from '../breakout/breakout-page-5'
// import BreakoutPage6 from '../breakout/breakout-page-6'
// import ChatroomPage from '../chatroom/chatroom-page'
import DisplayPage from "../display-gallary/display-page";
import Entrance from "../entrance/entrance";
import MedexFaculty from "../entrance/medex-faculty";
import Exterior from "../exterior/exterior";
// import InfoPage from '../info/info-page'
// import LobbyPage from '../lobby/lobby-page'
// import LoginPage from '../login/login-page'
import RootComponent from "../login/root-component";
// import ModeratorWebinar from '../moderator/moderatorWebinar'
// import SpeakerWebinar from '../speaker/speakerWebinar'
// import webinarHall2D from '../webinar-hall/webinar-hall-2d'
import WebinarHallMain from "../webinar-hall/webinar-hall-main";
// import WebinarHallPage from '../webinar-hall/webinar-hall-page'
import WebinarHall1 from "../webinar-hall/webinar-hall1";
import SalesRoom1 from "../display-gallary/sales-room1";
import LandingPage from "../videoPage/landingPage";
import { WebinarHall2 } from "../webinar-hall/webinar-hall2";
import WebinarHall3 from "../webinar-hall/webinar-hall3";
import WebinarHall4 from "../webinar-hall/webinar-hall4";
import WebinarHall5 from "../webinar-hall/webinar-hall5";
import WebinarHall6 from "../webinar-hall/webinar-hall6";
import WebinarHall7 from "../webinar-hall/webinar-hall7";
import WebinarHall8 from "../webinar-hall/webinar-hall8";
import WebinarHall9 from "../webinar-hall/webinar-hall9";
import WebinarHall10 from "../webinar-hall/webinar-hall10";
import WebinarHall11 from "../webinar-hall/webinar-hall11";
import WebinarHall12 from "../webinar-hall/webinar-hall12";
import WebinarHall13 from "../webinar-hall/webinar-hall13";
import WebinarHall14, { GWR_Room } from "../webinar-hall/gwr-room";
import WebinarHall15 from "../webinar-hall/webinar-hall15";
import WebinarHall16 from "../webinar-hall/webinar-hall16";
import WebinarHall17 from "../webinar-hall/webinar-hall17";
import WebinarHall18 from "../webinar-hall/webinar-hall18";
import WebinarHall19 from "../webinar-hall/webinar-hall19";
import WebinarHall20 from "../webinar-hall/webinar-hall20";
import WebinarHall21 from "../webinar-hall/webinar-hall21";
import WebinarHall22 from "../webinar-hall/webinar-hall22";
import WebinarHall23 from "../webinar-hall/webinar-hall23";
import WebinarHall24 from "../webinar-hall/webinar-hall24";
import WebinarHall25 from "../webinar-hall/webinar-hall25";
import WebinarHall26 from "../webinar-hall/webinar-hall26";
import Eventspage from "../events/Eventspage";
import LoginPage from "../login/login-page";
import WebinarRoom from "../webinar-hall/webinar-room";
import Registration from "../login/Registration";
import AgendaDialog from "../../agenda";
import MeetingRoom1 from "../display-gallary/meeting1";
import MeetingRoom2 from "../display-gallary/meeting2";
import MeetingRoom3 from "../display-gallary/meeting3";
import MeetingRoom4 from "../display-gallary/meeting4";
import MeetingRoom5 from "../display-gallary/meeting5";
import MeetingRoom6 from "../display-gallary/meeting6";
import MeetingRoom7 from "../display-gallary/meeting7";
import MeetingRoom8 from "../display-gallary/meeting8";
import MeetingRoom9 from "../display-gallary/meeting9";
import MeetingRoom10 from "../display-gallary/meeting10";
import NetworkingArea from "../display-gallary/display-gallery-page";
import WebinarHallMain2 from "../webinar-hall/webinar-hall-main2";
import GWR from "../webinar-hall/gwr";
import WebinarHall27 from "../webinar-hall/webinar-hall27";
// import SelfieZone from '../../selfie-zone/selfie-zone'

export default class RouterContainer extends Component {
  render() {
    return (
      <div>
        <Router history={createBrowserHistory}>
          <Switch>
            <Route exact path="/" component={LoginPage} />

            <Route exact path="/registration" component={Registration} />
            <div id="expoViewer">
              <Route exact path="/agenda1" component={AgendaDialog} />
              <Route exact path="/main/:uid" component={RootComponent} />
              <Route exact path="/exterior" component={Exterior} />
              <Route exact path="/entrance" component={Entrance} />
              <Route exact path="/events" component={Eventspage} />
              {/* <Route exact path="/agenda" component={AgendaPage} /> */}
              <Route exact path="/archive" component={ArchiveGallary} />
              <Route exact path="/archive1" component={ArchiveGallary1} />
              <Route exact path="/archive2" component={ArchiveGallary2} />
              <Route exact path="/archive3" component={ArchiveGallary3} />
              <Route exact path="/archive4" component={ArchiveGallary4} />
              <Route exact path="/archive5" component={ArchiveGallary5} />
              <Route exact path="/archive6" component={ArchiveGallary6} />
              <Route exact path="/archive7" component={ArchiveGallary7} />
              <Route exact path="/archive8" component={ArchiveGallary8} />
              <Route exact path="/archive9" component={ArchiveGallary9} />
              <Route exact path="/archive10" component={ArchiveGallary10} />
              <Route exact path="/archive11" component={ArchiveGallary11} />
              {/* <Route exact path="/IISF-faculty" component={MedexFaculty} /> */}

              <Route
                exact
                path="/entertainment-zone"
                component={BreakTimeZone}
              />
              <Route exact path="/display" component={DisplayPage} />
              <Route exact path="/network-area" component={NetworkingArea} />
              <Route exact path="/network-area1" component={MeetingRoom1} />
              <Route exact path="/network-area2" component={MeetingRoom2} />
              <Route exact path="/network-area3" component={MeetingRoom3} />
              <Route exact path="/network-area4" component={MeetingRoom4} />
              <Route exact path="/network-area5" component={MeetingRoom5} />
              <Route exact path="/network-area6" component={MeetingRoom6} />
              <Route exact path="/network-area7" component={MeetingRoom7} />
              <Route exact path="/network-area8" component={MeetingRoom8} />
              <Route exact path="/network-area9" component={MeetingRoom9} />
              <Route exact path="/network-area10" component={MeetingRoom10} />

              <Route exact path="/webinar-hall" component={WebinarHallMain} />
              <Route
                exact
                path="/webinar-hall-2"
                component={WebinarHallMain2}
              />
              <Route exact path="/webinar-room" component={WebinarRoom} />
              <Route exact path="/webinar-hall1" component={WebinarHall1} />
              <Route exact path="/webinar-hall2" component={WebinarHall2} />
              <Route exact path="/webinar-hall3" component={WebinarHall3} />
              <Route exact path="/webinar-hall4" component={WebinarHall4} />
              <Route exact path="/webinar-hall5" component={WebinarHall5} />
              <Route exact path="/webinar-hall6" component={WebinarHall6} />
              <Route exact path="/webinar-hall7" component={WebinarHall7} />
              <Route exact path="/webinar-hall8" component={WebinarHall8} />
              <Route exact path="/webinar-hall9" component={WebinarHall9} />
              <Route exact path="/webinar-hall10" component={WebinarHall10} />
              <Route exact path="/webinar-hall11" component={WebinarHall11} />
              <Route exact path="/webinar-hall12" component={WebinarHall12} />
              <Route exact path="/webinar-hall13" component={WebinarHall13} />
              {/* <Route exact path="/webinar-hall14" component={WebinarHall14} /> */}
              <Route exact path="/GWR-room" component={GWR_Room} />
              <Route exact path="/iisf2021" component={GWR} />
              <Route exact path="/webinar-hall15" component={WebinarHall15} />
              <Route exact path="/webinar-hall16" component={WebinarHall16} />
              <Route exact path="/webinar-hall17" component={WebinarHall17} />
              <Route exact path="/webinar-hall18" component={WebinarHall18} />
              <Route exact path="/webinar-hall19" component={WebinarHall19} />
              <Route exact path="/webinar-hall20" component={WebinarHall20} />
              <Route exact path="/webinar-hall21" component={WebinarHall21} />
              <Route exact path="/webinar-hall22" component={WebinarHall22} />
              <Route exact path="/webinar-hall23" component={WebinarHall23} />
              <Route exact path="/webinar-hall24" component={WebinarHall24} />
              <Route exact path="/webinar-hall25" component={WebinarHall25} />
              <Route exact path="/webinar-hall26" component={WebinarHall26} />
              <Route exact path="/webinar-hall27" component={WebinarHall27} />
            </div>
          </Switch>
        </Router>
      </div>
    );
  }
}
