const SpeakerArry = [
  {
    date: "11/12/2021",
    time: "10:00AM-11:30AM",
    eventName: "SLITF",
    topic: "INAUGURAL SESSION",
    speakers: [
      {
        speakersName: "Dr Ranjana Aggarwal",
      },
      {
        speakersName: "Dr Jitendra Singh",
      },
      {
        speakersName: "Shri Michael Vincent Lobo",
      },
      {
        speakersName: "Dr M.Ravichandran",
      },
      {
        speakersName: "Dr Shekhar C.Mande",
      },
      {
        speakersName: "Shri Jayant Sahasrabuddhe",
      },
      {
        speakersName: "Dr M.Ravichandran",
      },
      {
        speakersName: "Shri Mirza Javed Beg",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "11:45AM-01:15PM",
    eventName: "SLITF",
    topic: "INDIAN FREEDOM MOMENT-SCIENCE SCIENTIST&SCIENCE DEPLOMACY",
    speakers: [
      {
        speakersName: "Chair",
      },
      {
        speakersName: "Dr Ruchir Gupta",
      },
      {
        speakersName: "Dr Ranjana Aggarwal",
      },
      {
        speakersName: "Dr Ayan  Datta",
      },
      {
        speakersName: "Dr Jayanti Dutta",
      },
      {
        speakersName: "Dr Chaitanya  Giri",
      },
      {
        speakersName: "Shri Vivekanand Pai",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "02:00PM-03:30PM",
    eventName: "SLITF",
    topic: "SCIENCE LITERATURE IN REGINOAL LANGUAGE-CHALLANGES & INITIATIVE",
    speakers: [
      {
        speakersName: "Chair",
      },
      {
        speakersName: "Dr Nakul Parashar",
      },
      {
        speakersName: "Shri  lndranil  Sanyal",
      },
      {
        speakersName: "Dr Ruby  Mishra",
      },
      {
        speakersName: "Shri ASKVS Sharma",
      },
      {
        speakersName: " Prof. Pankaj S. Joshi",
      },
      {
        speakersName: "Shri K. R. Sudhakar Rao",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "03:45PM-05:30PM",
    eventName: "SLITF",
    topic: "VIGYAN KAVI SAMMELAN",
    speakers: [
      {
        speakersName: "Moderator",
      },
      {
        speakersName: "Shri Dattaprasad Jog",
      },
      {
        speakersName: "Lead Poets",
      },
      {
        speakersName: "Shri Sachin Manerikar",
      },
      {
        speakersName: "Shri Rutwik Phatak",
      },
      {
        speakersName: " Shri Noojilla Srinivas",
      },
      {
        speakersName: "Ms Smita Darshetkar",
      },
      {
        speakersName: "Dr Shubhrata Mishra",
      },
      {
        speakersName: "Shri Pankaj Prasun",
      },
      {
        speakersName: "Shri R. Laldinpuia",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:30AM",
    eventName: "SLITF",
    topic:
      "SCIENTIFIC SESSION 1- SPIRING SCIENTIST-ROAD TO SCIENTICFIC SUCCESS",
    speakers: [
      {
        speakersName: "Chair",
      },
      {
        speakersName: "Dr Vijay P. Bhatkar",
      },
      {
        speakersName: "Speakers",
      },
      {
        speakersName: "Dr K. Giribabu",
      },
      {
        speakersName: "Dr Bhavya B",
      },
      {
        speakersName: "Krishna",
      },
      {
        speakersName: "Dr Indu Elizabeth",
      },
      {
        speakersName: "Dr Manoj K. Patel",
      },
      {
        speakersName: "Dr Lidita D. S. Khandeparker",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:45AM-01:15PM",
    eventName: "SLITF",
    topic: "SCIENTIFIC SESSION 2- MEET THE AUTHOR",
    speakers: [
      {
        speakersName: "Moderator",
      },
      {
        speakersName: "Dr T. V. Venkateswaran",
      },
      {
        speakersName: "Authors,Prof",
      },
      {
        speakersName: "Hari Pulakkat",
      },
      {
        speakersName: "Ms Shweta Taneja",
      },
      {
        speakersName: "Dr Dinesh Sharma",
      },
      {
        speakersName: "Shri Devendra Mewari",
      },
      {
        speakersName: "Dr Pranay Lal",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "02:00PM-03:30PM",
    eventName: "SLITF",
    topic: "SCIENCE THROUGH PERFORMING ART",
    speakers: [
      {
        speakersName: "Chair: Leena Damle",
      },
      {
        speakersName: "Vigyankranti",
      },
      {
        speakersName: "Prarthana Kaushik",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3:45PM-4:45PM",
    eventName: "SLITF",
    topic:
      "SCIENTIFIC LITERATURE  FOR SCIENTIFIC ENGAGEMENT ISSUE CHALANGES& WAY AHEAD",
    speakers: [
      {
        speakersName: "Chair/Moderator",
      },
      {
        speakersName: "Dr  Somdatta  Karak",
      },
      {
        speakersName: "Shri Pallava Bagla",
      },
      {
        speakersName: "Dr   Geeta   Vani   Rayasam",
      },
      {
        speakersName: "Panelists: Dr Shivprasad Khened",
      },
      {
        speakersName: "Dr Frederick Noronha",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "5:00PM-6:00PM",
    eventName: "SLITF",
    topic: "VALEDICTORY SESSION",
    speakers: [
      {
        speakersName: "Dr Ranjana Aggarwal",
      },
      {
        speakersName: "Guests of Honour Prof. B.K. Mishra",
      },
      {
        speakersName: "Director",
      },
      {
        speakersName: "Prof",
      },
      {
        speakersName: "Harilal Bhaskara Menon",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "11:30PM-1:00PM",
    eventName: "ECOFEST",
    topic: "INAUGURATION ",
    speakers: [
      {
        speakersName: "Dr.Sangita M Kasture",
      },
      {
        speakersName: "Sh. Jayant Sahasrabudhe",
      },
      {
        speakersName: "Talk by Earth Shot Mr. Viduyt Mohan",
      },
      {
        speakersName: "Plenary Talk Dr. Sharad P Kale",
      },
      {
        speakersName: "Special Remarks Dr. Rajesh Gokhle",
      },
      {
        speakersName: "Inaugural Address Sh. Bhupender Yadav",
      },
      {
        speakersName: "Vote of Thanks Dr. Parmananda",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "2:15PM-3:30PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE THEME ENVIRONMENT AND ECOLOGY  2 PARALLEL SESSION ",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "3:45PM-5:00PM",
    eventName: "ECOFEST",
    topic: "WEBINAR SHOWCASING CLEAN ENERGY TECHNOLOGIES ",
    speakers: [
      {
        speakersName: "Dr. Sangita/ Dr. Divya",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "2:00PM-5:00PM",
    eventName: "ECOFEST",
    topic: "MOVIES SHOWCASING ",
    speakers: [
      {
        speakersName: "Dr. Sumit",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "9:30AM-1:00PM",
    eventName: "ECOFEST",
    topic: "PLENARY TALK ON WATER CONSERVATION SAGA OF TRIBAL CULTURE",
    speakers: [
      {
        speakersName: "Dr. Vivek/ Dr. Sumit",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "9:00AM-1:00PM",
    eventName: "ECOFEST",
    topic: "SITE VISIT  CLEAN DRIVE AT GOA BEACH",
    speakers: [
      {
        speakersName: "Dr. Sufala",
      },
      {
        speakersName: "Dr. Suhas",
      },
      {
        speakersName: "Dr. Parmanand",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "11:30AM-1:00PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME CLIMATE CHANGE AND CARBON NEUTRALITY - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "2:15PM-3:30PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME OUR OCEAN AND RIVER - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "3:45PM-5:00PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME FAUNA &FLORA AND CLEAN ENERGY TRANSITION - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek/ Dr. Sumit",
      },
      {
        speakersName: "Dr Sangita/Dr. Divya",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "ECOFEST",
    topic:
      "PLENARY TALK ON DEVELOPING SASTAINABLE TECHNOLOGIES FOR ECOSYSTEM DEVELOPMENT",
    speakers: [
      {
        speakersName: "DBT",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "9:00AM-1:00PM",
    eventName: "ECOFEST",
    topic: "SITE VISIT  TO WASTE TO ENERGY PLANT",
    speakers: [
      {
        speakersName: "Dr. Abhishek Mehta",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-1:30PM",
    eventName: "ECOFEST",
    topic: "JAGRUT BHARAT THEME ENVIRONMENT AWARENESS",
    speakers: [
      {
        speakersName: "NEERI (Dr AtyaKaple)/Dr.Sumit",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:15PM-3:30PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME  ENERGY AND SUSTAINABLE DEVELOPMENT GOAL - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3:45PM-5:00PM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME  SUSTAINABLE DEVELOP GOAL AND SUSTAINABLE DEVELOPMENT GOAL - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3:00PM-5:00PM",
    eventName: "ECOFEST",
    topic: "MOVIES SHOWCASING",
    speakers: [
      {
        speakersName: "Dr. Sumit",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:00PM-3:00PM",
    eventName: "ECOFEST",
    topic: "PLENARY TALK BY DR TARUN JINDAL",
    speakers: [
      {
        speakersName: "Dr. Parmanand",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "9:30AM-11:00AM",
    eventName: "ECOFEST",
    topic:
      "PANEL DISCUSSION /ROUND TABLE  THEME  TACKLING WASTE - PARALLEL SESSION",
    speakers: [
      {
        speakersName: "Dr. Vivek and team",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "9:00AM-1:00PM",
    eventName: "ECOFEST",
    topic: "SITE VISIT TO DR SALIM ALI BIRD SANCTUARY",
    speakers: [
      {
        speakersName: "Dr. Pmananard",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "11:00AM-12:00PM",
    eventName: "ECOFEST",
    topic: "CONCLUDING SESSION ",
    speakers: [
      {
        speakersName: "DBT",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "12:00PM-1:00PM",
    eventName: "ECOFEST",
    topic: "VALEDICTION AND PRIZE DISTRIBUTION",
    speakers: [
      {
        speakersName: "DBT",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "9:30AM-11:00AM",
    eventName: "GAMES&TOY FEST",
    topic: "VALEDICTION AND PRIZE DISTRIBUTION",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "11:30AM-1:00PM",
    eventName: "GAMES&TOY FEST",
    topic: "SCIENCE BEHIND TOY (ONLINE & OFFLINE)",
    speakers: [
      {
        speakersName: "DR JAYANT JOSHI..",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "2:00PM-4:30PM",
    eventName: "GAMES&TOY FEST",
    topic: "HOME MADE TOYS FOR TEACHING  (ONLINE)",
    speakers: [
      {
        speakersName: "SHRI MANISH JAIN",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "9:30AM-10:30AM",
    eventName: "GAMES&TOY FEST",
    topic: "TOYS OF INDIA BEFORE INDEPENDENCE (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "SH. GOPAL KHANNA",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10:30AM-11:30AM",
    eventName: "GAMES&TOY FEST",
    topic:
      "FUN WITH FLYING THINGS-BASIC AERODYNAMICS MADE EASY  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "CDR.T.R.A NARAYANAN",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "11:45AM-1:00PM",
    eventName: "GAMES&TOY FEST",
    topic: "CREATIVE THINGS FOR MAKING TOYS  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "SHRI ADITENDRA JAISWAL",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "2:00PM-3:30PM",
    eventName: "GAMES&TOY FEST",
    topic: "SIMPLE SCIENCE TOY MAKING  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "DR JAYANT JOSHI..",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "4:00PM-5:45PM",
    eventName: "GAMES&TOY FEST",
    topic:
      "PANEL DISCUSSION ON CHALANGES FOR INDIAN GAMES AND TOY INDUSTRY  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "SH PANKAJ SAPKAL",
      },
      {
        speakersName: "SH ANURAG GUPTA",
      },
      {
        speakersName: "SH ADITENDRA JAISHWAL",
      },
      {
        speakersName: "DR.PARAG MANKEEKAR..",
      },
      {
        speakersName: "Mrs D ROOPA",
      },
      {
        speakersName: "SHAILENDRA JAISWAL",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "9:30AM-11:00AM",
    eventName: "GAMES&TOY FEST",
    topic: "GAME- A TOOL OF LIFE SKILL DEVELOPMENT (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "Ms. MITAL SALIA",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-1:00PM",
    eventName: "GAMES&TOY FEST",
    topic: "DEEPAK FAB LAB SHOW  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "Mr DEEPAK PATHANIA ",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:00PM-3:30PM",
    eventName: "GAMES&TOY FEST",
    topic: "DEEPAK FAB LAB SHOW  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "Mr DEEPAK PATHANIA ",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3:45-5:00",
    eventName: "GAMES&TOY FEST",
    topic: "TEACHING TECHNIQUES THROUGH GAMES  (ONLINE&OFFLINE)",
    speakers: [
      {
        speakersName: "Ms. MITAL SALIA ",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "5:15PM-6:30PM",
    eventName: "GAMES&TOY FEST",
    topic: "VALEDICTORY SESSION",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "10:00AM-11:15AM",
    eventName: "CRAFT FEST",
    topic: "IISF MAIN INAUGURAL FUNCTION.",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "12:00PM-1:00PM",
    eventName: "CRAFT FEST",
    topic: "INAUGURAL OF TRADITIONAL CRAFT AND ARTISAN MEET AND EXPO",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "2:15PM-3:30PM",
    eventName: "CRAFT FEST",
    topic: "SESSION 1 (ACHIEVEMENT @75)",
    speakers: [
      {
        speakersName: "Shri Om Prakash Saklecha",
      },
      {
        speakersName: "Dr. Rajendra",
      },
      {
        speakersName: "Dr. Vinayak Khedkar",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "3:45PM-5:00PM",
    eventName: "CRAFT FEST",
    topic: "SESSION 2 LIVE STREAMING OF DIFFERENT ART &CRAFT FORMS",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "7:00PM-8:30PM",
    eventName: "CRAFT FEST",
    topic: "CULTURAL PROGRAMME",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "09:30PM-11:00PM",
    eventName: "CRAFT FEST",
    topic:
      "Session-XI/panel discussion Theme- Traditional Indian Tribal Handicrafts: Azadi ka Amrit Mahotsava",
    speakers: [
      {
        speakersName: "Dr. N Bharat Ballar",
      },
      {
        speakersName: "Dr. AK Srivastava",
      },
      {
        speakersName: "Dr. Mahesh Sharma",
      },
      {
        speakersName: "Shri Vijay Dutt Shridhar",
      },
      {
        speakersName: "Dr Kishore Sreenivasan",
      },
      {
        speakersName: "Dr. Anil Kothari",
      },
      {
        speakersName: "Padma shri Dr. Sharda Srinivasan",
      },
      {
        speakersName: "Mr Hanuman",
      },
      {
        speakersName: "Dr. Vishwas Chouhan",
      },
      {
        speakersName: "Dr. Sachin Tiwari",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "09:30AM-11:00AM",
    eventName: "CRAFT FEST",
    topic: "Valedictory of Traditional Crafts and Artisan Meet and Expo",
    speakers: [
      {
        speakersName: "Dr, Avanish Kumar Srivastava",
      },
      {
        speakersName: "Dr. AK Srivastava",
      },
      {
        speakersName: "Dr. Mahesh Sharma",
      },
      {
        speakersName: "Shri Vijay Dutt Shridhar",
      },
      {
        speakersName: "Dr Kishore Sreenivasan",
      },
      {
        speakersName: "Dr. Anil Kothari",
      },
      {
        speakersName: "Padma shri Dr. Sharda Srinivasan",
      },
      {
        speakersName: "Mr Hanuman",
      },
      {
        speakersName: "Dr. Vishwas Chouhan",
      },
      {
        speakersName: "Dr. Sachin Tiwari",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "09:30AM-11:00AM",
    eventName: "CRAFT FEST",
    topic:
      "Session-III Talk-Theme- Geographical Indicators (GIs) for Traditional Art and Crafts",
    speakers: [
      {
        speakersName: "Dr. N.K.Choubey",
      },
      {
        speakersName: "Sh. Muzaffar Ansari",
      },
      {
        speakersName: "Sh. Kaushik Patel",
      },
      {
        speakersName: "Sh. Rohit Kantilal Salvi",
      },
      {
        speakersName: "Sh. Vikas Tak,",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "11:30AM-01:00PM",
    eventName: "CRAFT FEST",
    topic: "Session –IV Highlighting the Effect of Tribe & Artisans for Future",
    speakers: [
      {
        speakersName: "Dr. Pravir Kashyap",
      },
      {
        speakersName: "Sh. Muzaffar Ansari,",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "02:15PM-03:30PM",
    eventName: "CRAFT FEST",
    topic: "Session-V",
    speakers: [
      {
        speakersName: "Dr. Sar Hashmi",
      },
      {
        speakersName: "Kunal Merchant,",
      },
      {
        speakersName: "Dr. Ashokan,",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "03:45PM-05:00PM",
    eventName: "CRAFT FEST",
    topic: "Session-VI Interaction session with Artisans",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "7:00PM-8:30PM",
    eventName: "CRAFT FEST",
    topic: "CULTURAL PROGRAMME",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "09:30AM-11:00AM",
    eventName: "CRAFT FEST",
    topic:
      "Session-VII- International S & T on Traditional Craft and Artisans –I",
    speakers: [
      {
        speakersName: "Dr. Daksh Agarwal",
      },
      {
        speakersName: "Dr. Piyush Dua",
      },
      {
        speakersName: "Dr. Mahammed Saif Mohammed Al Saidi",
      },
      {
        speakersName: "Oman",
      },
      {
        speakersName: "Dr. A.K.Srivastava",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-01:00PM",
    eventName: "CRAFT FEST",
    topic:
      "Session –VIII International S & T on Traditional Craft and Artisans –II (Online)",
    speakers: [
      {
        speakersName: "Prof. B.K. Mishra",
      },
      {
        speakersName: "Dr. Harneet KAur",
      },
      {
        speakersName: "Dr. Shishir Shirotiya",
      },
      {
        speakersName: "Ksenia Artist",
      },
      {
        speakersName: "Moscow",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "02:15PM-03:30PM",
    eventName: "CRAFT FEST",
    topic: "Session-IX/(Video Demonstration on Bharia Tribe",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "03:45PM-05:00PM",
    eventName: "CRAFT FEST",
    topic: "Session-X",
    speakers: [
      {
        speakersName: "V Ramagopal Rao",
      },
      {
        speakersName: "Mankajkumar Singh",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "7:00PM-8:30PM",
    eventName: "CRAFT FEST",
    topic: "CULTURAL PROGRAMME",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "10:00AM-10:50AM",
    eventName: "NEW AGE",
    topic: "Inaugural Function",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "11.15AM-12:15PM",
    eventName: "NEW AGE",
    topic: "Keynote Speaker",
    speakers: [
      {
        speakersName: "Prof. Shandar Ahmad",
      },
      {
        speakersName: "Shri Subhod Sachan",
      },
      {
        speakersName: "STPI",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "12:15PM-1:30PM",
    eventName: "NEW AGE",
    topic: "Panel Discussion Theme : AI-ML, Data Analytics, IoT & ESDM",
    speakers: [
      {
        speakersName: "Sridhar Muppidi",
      },
      {
        speakersName: "Sanjiv Jha",
      },
      {
        speakersName: "Rajul Agarwal",
      },
      {
        speakersName: "Avijit Guha",
      },
      {
        speakersName: "Rahul Shingrani",
      },
      {
        speakersName: "Prof. Kannan Moudgalya",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "02:30PM-04:30PM",
    eventName: "NEW AGE",
    topic: "Demonstration AI-ML, Data Analytics, IoT & ESDM",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10:30AM-01:30PM",
    eventName: "NEW AGE",
    topic: "Ethical Hacking (Online)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10:30AM-11:00AM",
    eventName: "NEW AGE",
    topic: "Keynote Speaker",
    speakers: [
      {
        speakersName: "Prof. Debi Prosad Dogra",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "11:30AM-01:00PM",
    eventName: "NEW AGE",
    topic: "Panel Discussion Theme : AR/VR/MR/XR & AI-ML, Data Analytics, IoT",
    speakers: [
      {
        speakersName: "Siddharth Mahajan",
      },
      {
        speakersName: "Anumukonda Ramesh",
      },
      {
        speakersName: "Prof. Mahadeva Prasanna",
      },
      {
        speakersName: "Prof. Kannan Moudgalya",
      },
      {
        speakersName: "Prof. Shandar Ahmad",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "02:00PM-04:00PM",
    eventName: "NEW AGE",
    topic: "Demonstration on AR/VR/MR/XR & AI-ML, Data Analytics, IoT",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:30AM-11:00AM",
    eventName: "NEW AGE",
    topic: "Keynote Speaker",
    speakers: [
      {
        speakersName: "Dr. Alok Srivastava",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-01:00PM",
    eventName: "NEW AGE",
    topic:
      "Panel Discussion Theme : Innovation Showcase in New age Technologies in Life Science",
    speakers: [
      {
        speakersName: "Dr. N. K Arora",
      },
      {
        speakersName: "Dr. Kapil Maithal",
      },
      {
        speakersName: "Prof. K Srinath Reddy",
      },
      {
        speakersName: "Prof. G. Padmanabhan",
      },
      {
        speakersName: "Prof. Raghavan Varadarajan",
      },
      {
        speakersName: "Dr. Sanjay Singh",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "02:00PM-04:00PM",
    eventName: "NEW AGE",
    topic: "Demonstration on Life Science",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "",
    eventName: "NSOIM",
    topic: "Inaugural session",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "4.00PM-5.00PM",
    eventName: "NSOIM",
    topic: "Welcome: INTRODUCTION TO NSOIM KEYNOTE ADRESS",
    speakers: [
      {
        speakersName: "Dr. D. Dutta",
      },
      {
        speakersName: "N.P.Rajive",
      },
      {
        speakersName: "Shri.JayanthSahasrabudhe",
      },
      {
        speakersName: "Dr.SudhirBadouria",
      },
      {
        speakersName: "Sadananda Chakrabarthi",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "5.00PM-6.30PM",
    eventName: "NSOIM",
    topic:
      "Technical session-1:Agriculture, Water & Environment-Challenges & Opportunities",
    speakers: [
      {
        speakersName: "Dr Sunil chathurvedi",
      },
      {
        speakersName: "Mahesh Sharma",
      },
      {
        speakersName: "Dr Bikash Roy",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "9.30AM-11.00AM",
    eventName: "NSOIM",
    topic:
      "Technical session-2  Addressing Health & Hygiene, livelihood issues to attain SDG",
    speakers: [
      {
        speakersName: "Dr Maneesha V Ramesh",
      },
      {
        speakersName: "Dr Shashi Bala Singh",
      },
      {
        speakersName: "Dr Roshan Lal",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11.30AM-1.30PM",
    eventName: "NSOIM",
    topic:
      "Technical session-3   STI interventions for social entrepreneurship and livelihood development ",
    speakers: [
      {
        speakersName: "Dr. Vipin Kumar",
      },
      {
        speakersName: "Dr. Satheesh Agnihotri",
      },
      {
        speakersName: "Dr Pramod kumar singh",
      },
      {
        speakersName: "Dr Sachin Mandavgane",
      },
      {
        speakersName: "Dr Debapriya dutta",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3.30PM-5.30PM",
    eventName: "NSOIM",
    topic:
      "Technical session –4  Presentations on grassroots models that address specific societal problems ",
    speakers: [
      {
        speakersName: "N.P.Rajive",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10.00AM-11.30AM",
    eventName: "NSOIM",
    topic: "Technical Session -5 Presentations on grassroots models (Contd)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "12.00PM-1.30PM",
    eventName: "NSOIM",
    topic:
      "Technical session -6 • Panel discussion with interactive  session -  Addressing the social sector concerns and opportunities • NSOIM 2021 Declaration and resolution @ 75",
    speakers: [
      {
        speakersName: "Dr Bhalchandra Sathey",
      },
      {
        speakersName: "Vice chair VibhaVani",
      },
      {
        speakersName: "Sadananda Chakrabarthi",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "4.00PM-5.30PM",
    eventName: "NSOIM",
    topic:
      "Concluding session: Honoring & Giving away Awards for outstanding contribution for grassroots development",
    speakers: [
      {
        speakersName: "N.P.Rajive ",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "INAUGURATION  ROOM 1 ",
    speakers: [
      {
        speakersName: " ",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "INAUGURATION  ROOM 2 ",
    speakers: [
      {
        speakersName: " ",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "INAUGURATION  ROOM 3 ",
    speakers: [
      {
        speakersName: " ",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "SSI - Scientists Students Interaction (MOES) ROOM 1 ",
    speakers: [
      {
        speakersName: " ",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 3 - Hands on Science Experiments – Activity 3 (MATH)  ROOM 2 ",
    speakers: [
      {
        speakersName: "DR. Brajesh Pandey",
      },
      {
        speakersName: "Symbiosis Institute of Technology",
      },
      {
        speakersName: "DR. Neeru Bhagat",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 1 – Hands on Science Experiments – Activity 1 (PHY)  ROOM 3",
    speakers: [
      {
        speakersName: "Dr. RB Agarwal",
      },
      {
        speakersName: "Shri. Ramesh Kumar",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "MTA – Mechanical Toys Activity ROOM 1",
    speakers: [
      {
        speakersName: "Dr. Ravindra Godbole",
      },
    ],
  },
  {
    date: "10/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "EWS - Engage With Science Activity (Vigyan Prasar) ROOM 2",
    speakers: [
      {
        speakersName: "Mr.Saurav Sen",
      },
      {
        speakersName: "Mr.Puneet Khad",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10.30AM-1.00PM",
    eventName: "SCI VILAGE FEST",
    topic:
      "VBS – Live Virtual visit to Bharati Station “Connect with Scientists at Antartica” (NCPOR) ROOM 1",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10.30AM-1.00PM",
    eventName: "SCI VILAGE FEST",
    topic:
      "VBS – Live Virtual visit to Bharati Station “Connect with Scientists at Antartica” (NCPOR) ROOM 2",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "10.30AM-1.00PM",
    eventName: "SCI VILAGE FEST",
    topic:
      "VBS – Live Virtual visit to Bharati Station “Connect with Scientists at Antartica” (NCPOR) ROOM 3",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 1 – Hands on Science Experiments – Activity 1 (PHY)  ROOM 1",
    speakers: [
      {
        speakersName: "DR. Brajesh Pandey",
      },
      {
        speakersName: "DR. Neeru Bhagat",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 2 - Hands on Science Experiments – Activity 2 (CHE) ROOM 2",
    speakers: [
      {
        speakersName: "Dr. Hemant Pande",
      },
      {
        speakersName: "Dr. Pratik Michael",
      },
      {
        speakersName: "Dr. Shoeb R. Lhan",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 3 - Hands on Science Experiments – Activity 3 (MATH)  ROOM 3",
    speakers: [
      {
        speakersName: "Dr. RB Agarwal",
      },
      {
        speakersName: "Shri. Ramesh Kumar, Govt",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "MTA – Mechanical Toys Activity ROOM 2",
    speakers: [
      {
        speakersName: "Dr. Ravindra Godbole",
      },
    ],
  },
  {
    date: "11/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "EWS - Engage With Science Activity (Vigyan Prasar) ROOM 3",
    speakers: [
      {
        speakersName: "Mr.Saurav Sen",
      },
      {
        speakersName: "Mr. Puneet Khad",
      },
      {
        speakersName: "Ms. Prachi Gupta",
      },
      {
        speakersName: "Mr. Karanjit Singh",
      },
      {
        speakersName: "Ms. Rukshi Alam",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "EXPO – Visit to Mega Science & Technology Exhibition ROOM 1",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 1 – Hands on Science Experiments – Activity 1 (PHY)  ROOM 2",
    speakers: [
      {
        speakersName: "DR. Brajesh Pandey",
      },
      {
        speakersName: "DR. Neeru Bhagat",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 2 - Hands on Science Experiments – Activity 2 (CHE) ROOM 3",
    speakers: [
      {
        speakersName: "Dr. Hemant Pande",
      },
      {
        speakersName: "Dr. Pratik Michael",
      },
      {
        speakersName: "Dr. Shoeb R. Lhan",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 3 - Hands on Science Experiments – Activity 3 (MATH)  ROOM 1",
    speakers: [
      {
        speakersName: "Dr. RB Agarwal",
      },
      {
        speakersName: "Shri. Ramesh Kumar",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "EXPO – Visit to Mega Science & Technology Exhibition ROOM 2",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "1.30PM-3.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "SSI - Scientists Students Interaction (MOES) ROOM 3",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "EWS - Engage With Science Activity (Vigyan Prasar) ROOM 1",
    speakers: [
      {
        speakersName: "Mr.Saurav Sen",
      },
      {
        speakersName: "Mr. Puneet Khad",
      },
      {
        speakersName: "Ms. Prachi Gupta",
      },
      {
        speakersName: "Mr. Karanjit Singh",
      },
      {
        speakersName: "Ms. Rukshi Alam",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "EXPO – Visit to Mega Science & Technology Exhibition ROOM 2",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "3.30PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "MTA – Mechanical Toys Activity ROOM 3",
    speakers: [
      {
        speakersName: "Mr. Ravindra Godbole, Pune",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "HSE 2 - Hands on Science Experiments – Activity 2 (CHE) ROOM 1",
    speakers: [
      {
        speakersName: "Dr. Hemant Pande",
      },
      {
        speakersName: "Dr. Pratik Michael",
      },
      {
        speakersName: "Dr. Shoeb R. Lhan",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "SSI - Scientists Students Interaction (MOES) ROOM 2",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10.30AM-12.30PM",
    eventName: "SCI VILAGE FEST",
    topic: "EXPO – Visit to Mega Science & Technology Exhibition ROOM 3",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "2:00PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "VALEDICTORY ROOM 1",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "2:00PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "VALEDICTORY ROOM 2",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "2:00PM-5.00PM",
    eventName: "SCI VILAGE FEST",
    topic: "VALEDICTORY ROOM 3",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "9:00AM-10:00AM",
    eventName: "FILM FEST",
    topic: "Registration & Attendance",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },

  {
    date: "13/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },

  {
    date: "13/12/2021",
    time: "11:30AM-12:00PM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },

  {
    date: "13/12/2021",
    time: "11:30AM-12:00PM",
    eventName: "FILM FEST",
    topic: "nteraction with the Festival Jury",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "2:00PM-3:30PM",
    eventName: "FILM FEST",
    topic: "Award Ceremony",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "3:30PM",
    eventName: "FILM FEST",
    topic:
      "Departure to Parade Ground, DB Road, Campal, Panaji, Goa for Valedictory Function of IISF",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) ESG Auditorium II",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "11:30AM-01:00AM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) ESG Auditorium III",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "13/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic:
      "Master Class 3 Making of - Imaging Gandhi / Imagining the Mahatma: Portrayal of Mahatma Gandhi in Indian Cinema Media Room",
    speakers: [
      {
        speakersName: "by Mr Nandan Kudhyadi",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "9:00AM-10:00AM",
    eventName: "FILM FEST",
    topic: "Registration & Attendance",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-01:00PM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:00PM-4:00PM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "4:15PM-6:30PM",
    eventName: "FILM FEST",
    topic: "Screening of Festival Films (Competitive & Non-Competitive)",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "6:30PM",
    eventName: "FILM FEST",
    topic:
      "Departure to Parade Ground, DB Road, Campal, Panaji, Goa for cultural programme and dinner",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic: "Post-pandemic Filmmaking: Quark Workshop ESG Auditorium II",
    speakers: [
      {
        speakersName: "Mr Nandan Saxena ",
      },
      {
        speakersName: "Mrs Kavita Bahl",
      },
      {
        speakersName: "Sr. Filmmakers",
      },
      {
        speakersName: "Mr Sidharth Saxena",
      },
      {
        speakersName: "Mr Rahul Rajoriya ",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:30AM-1:00PM",
    eventName: "FILM FEST",
    topic: "Post-pandemic Filmmaking: Quark Workshop ESG Auditorium II",
    speakers: [
      {
        speakersName: "Mr Nandan Saxena ",
      },
      {
        speakersName: "Mrs Kavita Bahl",
      },
      {
        speakersName: "Sr. Filmmakers",
      },
      {
        speakersName: "Mr Sidharth Saxena",
      },
      {
        speakersName: "Mr Rahul Rajoriya ",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:00PM-4:00PM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) ESG Auditorium II",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "4:15PM-7:00PM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) ESG Auditorium II",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) ESG Auditorium II",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "10:00AM-11:00AM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) Media Room",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "11:00AM-12:00PM",
    eventName: "FILM FEST",
    topic:
      "Screening of Festival Films (Competitive & Non-Competitive) Media Room",
    speakers: [
      {
        speakersName: "",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "12:00PM -1:00PM",
    eventName: "FILM FEST",
    topic: "Master Class 2 Story Telling and Science Communication Media Room",
    speakers: [
      {
        speakersName: "Mr Santosh Pandey",
      },
      {
        speakersName: "Managing Editor",
      },
      {
        speakersName: "India Science Wire",
      },
      {
        speakersName: "Vigyan Prasar",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "2:00PM -3:30PM",
    eventName: "FILM FEST",
    topic:
      "Panel Discussion 1 Science Communication, Popularisation and Extension (ScoPE) for Filmmakers and communicators in India @75 Media Room",
    speakers: [
      {
        speakersName: "Mrs Kinkini Dasgupta Misra",
      },
      {
        speakersName: "Co-Chair: Mr Fredrick Noronha",
      },
      {
        speakersName: "Scientist F Mr Inderjit Singh ",
      },
      {
        speakersName: "Mr Inderjit Singh ",
      },
      {
        speakersName: "Mr Nandan Saxena",
      },
      {
        speakersName: "Dr Upendra Ayodhya",
      },
      {
        speakersName: "Dr B. K. Tyagi",
      },
      {
        speakersName: "Dr Ruchita Sujai Chowdhary",
      },
    ],
  },
  {
    date: "12/12/2021",
    time: "4:15PM-5:45PM",
    eventName: "FILM FEST",
    topic:
      "Panel Discussion 2 Scope and Structure of Science, Environment and Health Communication in Mass Communication, Films and Journalism Studies Media Room",
    speakers: [
      {
        speakersName: "Dr Dhruba Jyoti Pati",
      },
      {
        speakersName: "Co-Chair/s:,Dr T.V. Venkateswaran",
      },
      {
        speakersName: "Panellists:Mrs Kavita Bahl",
      },
      {
        speakersName: "Dr Rintu Nath",
      },
      {
        speakersName: "Shri Sabyesachi Bharti",
      },
      {
        speakersName: "Dr Bharat Bhushan",
      },
      {
        speakersName: "Dr Gaurav Jain",
      },
    ],
  },
];

export default SpeakerArry;
