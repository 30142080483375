import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import image from "../images/Group.png"
import PortraitModeWarning from "../commone/portrainModeWarning";

const roomName = "webinar-room";
const expoName = "goa-virtual-meet";
const sceneName = "webinar-room";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <React.Fragment>
      <PortraitModeWarning />
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </React.Fragment>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export class WebinarRoomPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      myArray: [],
      allRooms: [
        {
          roomName: "International Science Film Festival of India",
          roomId: 1,
          webinarHall: [
            {
              roomName: "Science Film Festival - Hall 1",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/eco.png",
              gotoEvent: "/webinar-hall1",
            },
            {
              roomName: "Science Film Festival - Hall 2",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/fgt.png",
              gotoEvent: "/webinar-hall2",
            },
            {
              roomName: "Science Film Festival - Hall 3",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/gist.png",
              gotoEvent: "/webinar-hall3",
            },
          ],
        },
        {
          roomName: "Science Literature festival",
          roomId: 2,
          webinarHall: [
            {
              roomName: "Science Literature Festival - Room 1",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/gist.png",
              gotoEvent: "/webinar-hall4",
            },
            {
              roomName: "Science Literature Festival - Room 2",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall5",
            },
          ],
        },
        {
          roomName: "Engineering Students festival",
          roomId: 3,
          webinarHall: [
            {
              roomName: "ESFV Physical Room - 1",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall6",
            },
            {
              roomName: "ESFV Virtual Room - 1",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/fgt.png",
              gotoEvent: "/webinar-hall7",
            },
            {
              roomName: "ESFV Virtual Room - 2",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/eco.png",
              gotoEvent: "/webinar-hall8",
            },
            {
              roomName: "ESFV Virtual Room - 3",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/isf.png",
              gotoEvent: "/webinar-hall9",
            },
          ],
        },
        {
          roomName: "Science Village Festival",
          roomId: 4,
          webinarHall: [
            {
              roomName: "Science Village Festival",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/isf.png",
              gotoEvent: "/webinar-hall10",
            },
            // {
            //   roomName: "Kala Academy - Darya sangam2",
            //   imageSrc:
            //     "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/gist.png",
            //   gotoEvent: "/webinar-hall11",
            // },
            // {
            //   roomName: "Kala Academy - Darya sangam3",
            //   imageSrc:
            //     "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/fgt.png",
            //   gotoEvent: "/webinar-hall12",
            // },
          ],
        },
        {
          roomName: "Global Indian scientists & technocrats meet",
          roomId: 5,
          webinarHall: [
            {
              roomName: `GIST Festival - Aahaar Kranti Room`,
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/tca.png",
              gotoEvent: "/webinar-hall16",
            },
            {
              roomName: "GIST Festival - Agriculture Room",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/webinar.png",
              gotoEvent: "/webinar-hall17",
            },
            {
              roomName: "GIST Festival - Education Room",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall18",
            },
          ],
        },
        {
          roomName: "ECO fest",
          roomId: 6,
          webinarHall: [
            {
              roomName: `ECO Fest Physical \xa0 Room - 1`,
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/slf.png",
              gotoEvent: "/webinar-hall19",
            },
            {
              roomName: "ECO Fest Physical \xa0 Room - 2",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/nats.png",
              gotoEvent: "/webinar-hall20",
            },
            {
              roomName: "ECO Fest Physical \xa0 Room - 3",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall21",
            },
          ],
        },
        {
          roomName: "National social organisation & institutions meet",
          roomId: 7,
          webinarHall: [
            {
              roomName: "NSOIM - 1",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall23",
            },
            {
              roomName: "NSOIM - 2",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall24",
            },
            {
              roomName: "NSOIM - 3",
              imageSrc:
                "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png",
              gotoEvent: "/webinar-hall25",
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    console.log("iknlm;", this.props.roomId);

    // updateLastSceneEndTime(roomName);
  }
  componentDidUpdate() {
    if (this.state.id !== this.props.roomId) {
      let myNewArray = this.state.allRooms.filter(
        (obj) => obj.roomId === this.props.roomId
      );
      this.setState(
        {
          myArray: myNewArray[0].webinarHall,
          id: this.props.roomId,
        },
        () => {
          console.log("filter Data :: ", this.state.myArray);
        }
      );
    }
  }

  gameOpen = (name) => {
    this.props.onClose();
    window.location.href = `${name}`;
  };

  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth={"md"}
          onClose={this.props.onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.onClose}
            style={{
              display: "flex",
              justifyContent: "center",
              color: "wheat",
              // backgroundImage:
              //   "url(" +
              //   "https://i.pinimg.com/736x/91/cb/88/91cb8828e4a86c69815dd1be800aea1d.jpg" +
              //   ")",
              backgroundColor : "transparent"
            }}
          >
            {/* <div className="row">
              <div className="col-12">Event Hall</div>
            </div> */}
          {/* </DialogTitle> */}

          <DialogContent
            dividers
            className="productdialogheight"
            style={{
              backgroundColor: "transparent",
              // backgroundImage:
              //   "url(" +
              //   "https://1.bp.blogspot.com/-gyfFjszBKFQ/X2tTqPezJFI/AAAAAAAAIw8/japzAo3R8F88fUdcZmwoUDzT2Vp6GlhngCLcBGAsYHQ/s16000/gamer.png" +
              //   ")",
            }}
          >
            {/* {this.state.noDataImage ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={NoData} style={{ height: "270px" }} />
              </div>
            ) : ( */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.state.myArray.map((room, index) => (
                <React.Fragment key={index}>
                  <div
                    className=""
                    onClick={() => this.gameOpen(room.gotoEvent)}
                    style={{ paddingLeft: "5px", paddingRight: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <div
                      id="webinarhallcard"
                      style={{
                        background: "transparent",
                        // borderStyle: "double",
                        // border: "4px solid beige",
                      }}
                    >
                      <div
                        className="card-image waves-effect waves-block waves-light"
                        onClick={() => this.gameOpen(room.gotoEvent)}
                      >
                        <div className="game">
                          <img src={image} alt="" />
                          <span className="game-span">{room.roomName}</span>
                        </div>
                      </div>
                      {/* <div className="card-content">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            color: "wheat",
                          }}
                          className="card-title activator"
                        >
                          {room.roomName}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {/* // )} */}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default WebinarRoomPopup;
