import React, { Component } from "react";
import Exterior from "../exterior/exterior";
import landingImg from "../images/loginbanner.jpg";
import landscapeOnlyWarningImg from "../images/rotate-smartphone.png";
import mainVideo from "../images/mainVideo.mp4";

// let mainVideo = `https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Rubber-Board/EntryVideo-c.mp4`;

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoStart: false,
      loadViewer: false,
    };
  }

  componentDidMount() {
    var vid = document.getElementById("mainVideoEle");
    vid.oncanplaythrough = () => {
      console.log("called after video load");
      var callEvent = new CustomEvent("videoLoaded", { detail: "Testing" });
      document.body.dispatchEvent(callEvent);
    };
  }

  playvideo() {
    this.setState(
      {
        isVideoStart: true,
      },
      () => {
        var vid1 = document.getElementById("mainVideoEle");
        // vid1.onloadedmetadata = () => {
        vid1.play();
        // };

        document
          .getElementById("mainVideoEle")
          .addEventListener("ended", this.myHandler, false);
      }
    );
  }

  skipVideo = () => {
    var vid1 = document.getElementById("mainVideoEle");
    vid1.pause();
    this.myHandler();
  }
  myHandler = (e) => {
    window.location.href = "/entrance";
    this.setState({
      loadViewer: true,
    });
  };
  render() {

    return (
      <div>
        <div className="overlayPotraitMode">
          <div id="portraitModeOnly">
            <img alt="landscape only" src={landscapeOnlyWarningImg} />
            <p>This website is only viewable in landscape mode</p>
          </div>
        </div>
        
        <div>
          <video
            preload="true"
            id="mainVideoEle"
            src={mainVideo}
            playsInline
            webkit-playsInline
            type="video/mp4"
            className="mainvideoEle"
          />
          <button onClick={this.skipVideo} className="skipButton">
            <span>Skip</span>
          </button>
        </div>
      </div>
    );
  }
}

export default LandingPage;
