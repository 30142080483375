/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from 'react'
// import "src/pages/events/Events.css";
import "../events/Events.css";

export default class Marquee extends Component {
    render() {
        return (
            <div>
                <div className="position-marquee">
                    <marquee width="95%" direction="left" behavior="scroll" scrollamount="12">
                      Organizing Team of IISF Pays Tribute to
                      CDS General Late Bipin Rawat.
                      IISF inaugural function stands cancel for Today. New Time will be informed shortly.
                    </marquee>
                  </div>
            </div>
        )
    }
}
