import React, { Component } from "react";
import webinarHall2d from "../images/webinar/360 events.png";
// import "./css/main.css"
import videojs from "video.js";
import Header from "../header/header";
import Footer from "../footer/footer-page";
import ChatPanel from "../chatpanel/ChatPanel";
import { analyticsFunction, updateLastSceneEndTime } from "../functions/helpers/analytics-function";
import PortraitModeWarning from "../commone/portrainModeWarning";

const liveFormat = "application/x-mpegURL";
const liveVideoSrc = "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8";

const roomName = "GWR";
const expoName = "goa-virtual-meet";
const sceneName = "GWR";
class GWR extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: true,
    };
  }

  componentDidMount() {
    // videojs("liveVideo").src({
    //   type: liveFormat,
    //   src: liveVideoSrc ? liveVideoSrc : "",
    // });
    // this.changeStateForLoader();
    localStorage.setItem("Location", "GWR");

    // this.updateCollection();

    analyticsFunction(roomName);
    // updateLastSceneEndTime(roomName);
  }

  changeStateForLoader = (value) => {
    videojs("liveVideo").play();

    // this.setState({isloading:value})
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        {/* <Header headerType="user" /> */}
        <img
          alt="webinarImage"
          className="imageClass"
          src={webinarHall2d}
        ></img>
        {/* <video
                id="liveVideo"
                src=""
                loop
                type="application/x-mpegURL"
                className="audiVideo video-js vjs-default-skin"
                crossOrigin="anonymous"
                playsInline
              /> */}
        {/* <div style={{ padding: "56.25% 0 0 0", position: "relative" , overflow:"hidden"}}> */}
        <iframe
          src="https://vimeo.com/event/1644648/embed"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: "115px",
            height: "47vh",
            left: "31.3vw",
            width: "36.4vw",
            background: "black",
            objectFit: "fill",
            overflow: "hidden",
          }}
          title="Goa Test Event"
        ></iframe>
        {/* </div> */}
        <script src="https://player.vimeo.com/api/player.js"></script>
        {/* <ChatPanel roomName="GWR" /> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default GWR;
