/* eslint-disable */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable object-shorthand */

import React, { Component } from "react";
import PortraitModeWarning from "../commone/portrainModeWarning";
import Footer from "../footer/footer-page";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";
import Header from "../header/header";
// import "./meeting-room.css";
import salesRoomImg from "../images/network/Networking area ( S.literature ).jpg";
import { StartInvestorMeeting } from "./setup-meeting";

const roomName = "networkin-area2"
const expoName = 'goa-virtual-meet';
const sceneName = 'networkin-area2'

const rotatemobile =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/rotate-mobile.png";

export default class MeetingRoom2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    try {
      
      if (localStorage.getItem('email') && localStorage.getItem('uid')) {
          console.warn('welcome')
      } else {
          window.location.href = '/'
      }

      localStorage.setItem('Location', 'networkin-area2')

// this.updateCollection();
updateLastSceneEndTime(roomName);

      let loginUserEmail = localStorage.getItem("email");
      let isModerator = false;

      loginUserEmail === "edp@rubberboard.org.in"
        ? (isModerator = true)
        : (isModerator = false);

      StartInvestorMeeting(
        "Science Literature festival.",
        this.showMeetingListAfterMeetingEnd,
        isModerator
      );
    } catch (err) {
      console.log("componentDidMount", err, "meeting-room");
    }
  }

  showMeetingListAfterMeetingEnd = () => {
    try {
      // analyticsFunction("showMeetingListAfterMeetingEnd");
      // this.props.endMeeting();
      window.location.href="/network-area"
    } catch (err) {
      console.log("showMeetingListAfterMeetingEnd", err, "meeting-room");
    }
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />

          <Header/>
        {/* Background Image */}
        <div className="fullscreenBgImage">
          <img
            alt=""
            crossOrigin="anonymous"
            src={salesRoomImg}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        {/* Meeting Main Div */}
        <div id="meet" className="positionmeet" />

        {/* Mobile device overlay for Portrait Mode */}
        {/* <div className="overlaylandscape">
          <img
            src={rotatemobile}
            crossOrigin="anonymous"
            alt=""
            className="rotateimg"
          />
          <p className="rotatetext">Rotate your device to join meeting</p>
        </div> */}
        <Footer/>
      </div>
    );
  }
}
