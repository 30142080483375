import React, { Component } from 'react'
import PortraitModeWarning from '../commone/portrainModeWarning';
import VideoInDialog from '../display-gallary/video-in-dialog';
import Footer from '../footer/footer-page'
import authFirebaseApp from '../functions/features/auth-firebase-app';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import Header from '../header/header'
const expoName = 'goa-virtual-meet';
const roomName = 'archive6'
const archiveGalleryIframe = 'https://angles-expo.firebaseapp.com/dekho/60051b2a8c5ae500111e692a/0/novr/none'
export default class ArchiveGallary6 extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false
        })
        // this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user archive', user)
        //         // ...
        //     }
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //         // User is signed out
        //         // ...
        //     }
        // });
        // if( localStorage.getItem('userRole')==='user' && localStorage.getItem('uid') !== ''){
        //     console.warn('welcome')
        // } else{
        //     window.location.href = '/'
        // }
        localStorage.setItem('Location', 'archive-gallery')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('archiveGalleryProject');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        // updateLastSceneEndTime(roomName)
    }



    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }



    projectButtonClick = (e) => {
        console.log('called on lobby arrow', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive') {
                window.location.href = '/archive'
            }
        }
        if (e.data.isLinkClicked) {
            if (e.data.url) {
                if (e.data.url.includes('youtube.com') || e.data.url.includes('brightcove')) {
                    this.setState({
                        videoUrl: e.data.url,
                        isDialogOpen: true,
                    })

                } else {
                    window.open(e.data.url, '_blank');
                }
                // const form = document.createElement("form");
                // form.setAttribute('method', "post");
                // form.setAttribute('target', '_blank');
                // form.setAttribute('action', e.data.url);
                // document.body.append(form);
                // form.submit();
            }
        }
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }


    render() {
        return (
            <div>
                <PortraitModeWarning />
                <Header headerType="user" />
                <iframe id="archiveGalleryProject" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={archiveGalleryIframe}>
                </iframe>
                <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                <Footer />
            </div>
        )
    }
}
