/* eslint-disable */

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import centerImg from "./pages/images/sir.png";
import anglesimg from "./pages/images/loading.gif";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "auto !important",
    width: "auto !important",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: "#3FA9F4",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function AlertDialog(props) {
  const { alertDialog, setAlertDialog } = props;
  const classes = useStyles();

  return (
    <Dialog open={alertDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <img src={centerImg} alt="" style={{width : "100px"}}/>
        </IconButton>
        {/* <div className="loader-position">
          <img src={anglesimg} alt="" />
          <img src={centerImg} alt="" className="centerLoadImg" />
        </div> */}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle1">{alertDialog.title}</Typography>
        <Typography variant="subtitle2">{alertDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text="Ok"
          color="primary"
          variant="contained"
          onClick={() => setAlertDialog()}
          style={{ backgroundColor: "#3FA9F4" }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
