/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Events.css";
import "../helpers/footer/footer.css"
import {
  analyticsFunction,
  updateLastSceneEndTime,
} from "../functions/helpers/analytics-function";
import LandingPage from "../videoPage/landingPage";
import PortraitModeWarning from "../commone/portrainModeWarning";
import AlertDialog from "../../AlertDialog";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import downArrow from "../images/downArrow.png";
import bannerbg from "../images/banner/ban1.png";
import LoadingPage from '../loading/loading-page'
import Marquee from "../Marquee";
import Certificate from "../certificate/certificate";

let roomName = "events";
export default class Eventspage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginUser: false,
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      videoLoading: null,
      isOpenCertificate: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem("email") && localStorage.getItem("uid")) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }
    // localStorage.clear();
    document.body.addEventListener(
      "videoLoaded",
      (event) => {
        console.log("called after video load in listner", event);
        this.setState({
          videoLoading: false,
        });
      },
      { once: true }
    );
    analyticsFunction("entrance");
    // updateLastSceneEndTime(roomName);
  }

  setAlertDialog = () => {
    this.setState({
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    });
  };

  videoPlay = () => {
    // this.setState({
    //   alertDialog: {
    //     isOpen: true,
    //     title: "The website will be acessible from 10th December 2021 onwards",
    //   },
    // });
    this.setState(
      {
        isLoginUser: true,
        videoLoading: true,
      },
      () => {
        var vid1 = document.getElementById("mainVideoEle");
        // vid1.onloadedmetadata = () => {
        vid1.play();
        document
          .getElementById("mainVideoEle")
          .addEventListener("ended", this.myHandler, false);
      }
    );
  };

  openCertificate = () => {
    this.setState({
      isOpenCertificate: true
    })
  }

  onEvent = () => {
    window.scrollTo(0, 835);
  };

  redirectEvent = (component) => {
    window.location.href = `${component}`;
  };

  myHandler = (e) => {
    window.location.href = "/entrance";
    // this.setState({
    //   loadViewer: true,
    // });
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        {!this.state.isLoginUser && (
          <>
            <section
              className="hero-area wow fadeInUp mb-0"
              style={{
                backgroundImage:
                  'url("https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/2Asset+3-.png")',
                visibility: "visible",
                animationDuration: "1.5s",
                animationDelay: "700ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="container-fluid px-0" style={{ height: "100%" }}>
                <div className="row banner-row ">
                  <div
                    // className="button-and-social-area wow fadeInUp ml-3"
                    data-wow-duration="1.5s"
                    data-wow-delay="800ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1.5s",
                      animationDelay: "800ms",
                      animationName: "fadeInUp",
                      position: "absolute",
                      top: 0,
                      right: 10,
                      zIndex: 10,
                    }}
                  >
                    <a
                      //   href="https://www.scienceindiafest.org/aboutiisf2021"
                      href="https://events.scienceindiafest.org/"
                      className="skip-btn"
                      target="_blank"
                    >
                      Skip to content{" "}
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="hero-content-wrapper">
                      <div className="hero-content">
                        <div
                          className="title-section-area wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="800ms"
                        >
                          <h1 className="banner-title mb-3">
                            India International Science Festival
                          </h1>
                          <p>
                            {" "}
                            India International Science Festival which intends
                            to inspire curiosity and make learning more
                            rewarding.
                          </p>
                        </div>
                        {/* <div className="margin-bottom-60"> */}
                        <div className="d-flex flex-row">
                          <div class="d-flex">
                            <button
                              onClick={this.videoPlay}
                              class="videoEnterButton"
                            >
                              <span>Enter Festival </span>
                            </button>
                          </div>
                          <div class="d-flex" style={{marginLeft : "80px"}}>
                            <button
                              onClick={this.openCertificate}
                              class="videoEnterButton"
                            >
                              <span>Download Certificate </span>
                            </button>
                          </div>
                        </div>
                        {/* <div class="d-flex">
                          <div
                            className="videoEnterButton wow fadeInUp"
                            data-wow-duration="1.5s"
                            data-wow-delay="800ms"
                            style={{
                              visibility: "visible",
                              animationDuration: "1.5s",
                              animationDelay: "800ms",
                              animationName: "fadeInUp",
                            }}
                          >
                            <a
                              href="https://www.scienceindiafest.org/register"
                              className=" btnEvent downloadbtn"
                            >
                              Start{" "}
                            </a>
                          </div>
                        </div> */}

                        <div
                          id="countdown"
                          class="countdown  wow fadeInUp "
                          data-wow-duration="1.5s"
                          data-wow-delay="800ms"
                          style={{ display: "none" }}
                        >
                          <div className="counter-item">
                            <i className="icon icon-ring-1Asset-1"></i>
                            <span className="days">00</span>
                            <div className="smalltext">Days</div>
                          </div>
                          <div className="counter-item">
                            <i className="icon icon-ring-4Asset-3"></i>
                            <span className="hours">00</span>
                            <div className="smalltext">Hours</div>
                          </div>
                          <div className="counter-item">
                            <i className="icon icon-ring-3Asset-2"></i>
                            <span className="minutes">00</span>
                            <div className="smalltext">Minutes</div>
                          </div>
                          <div className="counter-item">
                            <i className="icon icon-ring-4Asset-3"></i>
                            <span className="seconds">00</span>
                            <div className="smalltext">Seconds</div>
                          </div>
                          <p id="demo"></p>
                        </div>
                        <div className="banner-info-meta ">
                          <ul className=" d-flex">
                            <li>
                              <p>
                                <DateRangeIcon />
                                10-13 December, 2021
                              </p>
                            </li>
                            <li>
                              <p>
                                <LocationOnIcon />
                                Panaji, Goa, India{" "}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 hideMobile">
                    <div className="solar-animation ">
                      <div className="solar-system">
                        <div className="circle2 cir"></div>

                        <div className="sun">
                          <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/iisf.png" />
                          {/* <img alt="" /> */}

                          <div class="earth2 size1">
                            <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/FreedomStruggle.png" />
                          </div>
                          <div class="earth22 size1">
                            <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/Achievments.png" />
                          </div>
                          <div class="earth23 size1">
                            <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/Actions.png" />
                          </div>
                          <div class="earth24 size1">
                            <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/Economy.png" />
                          </div>
                          <div class="earth25 size1">
                            <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/iisf/Mask_Group_50.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 hideMobile"
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      bottom: "100px"
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center hideMobile">
                      <div className="downArrow" onClick={() => this.onEvent()}>
                        <img src={downArrow} alt="down arrow" />
                      </div>
                    </div>
                  </div>
                  {/* <Marquee /> */}
                </div>
              </div>
            </section>
            <section className="events-bg events-conference">
              <div className="container-fluid" id="eventDiv">
                {/* <div className="d-flex justify-content-end">
                                    <button onClick={this.videoPlay} class="videoEnterButton"><span>Enter Website </span></button>
                                </div> */}
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="section-title-area">
                      <h2 class="sub-title">Events</h2>
                      <h2 class="section-heading my-4">
                        IISF 2021 Events at a Glance
                      </h2>
                      <p>
                        The aim is to engage the public with science and
                        celebrate the joy of science and show the ways how
                        science, technology, engineering and mathematics (STEM)
                        provide us with the solutions to improve our lives.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* cinema card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            {" "}
                            CREATIVITY IN SCIENCE THROUGH CINEMA.{" "}
                          </p>
                          <h3 className="ts-title sm">
                            International Science Film Festival Of India 2021
                          </h3>
                        </div>
                        <p>filmfestival@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* cinema card end */}
                  {/* LITERATURE card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CREATIVITY IN SCIENCE THROUGH LITERATURE.
                          </p>
                          <h3 className="ts-title sm">
                            Science Literature Festival
                          </h3>
                        </div>
                        <p>vigyanika@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* LITERATURE card end */}
                  {/* ENGINEERING card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CELEBRATING CREATIVITY OF ENGINEERS.
                          </p>
                          <h3 className="ts-title sm">
                            Engineering Students Festival
                          </h3>
                        </div>
                        <p>engistudfest@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* ENGINEERING card end */}
                  {/* Village Festival card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CELEBRATING SCIENCE WITH RURAL STUDENTS.
                          </p>
                          <h3 className="ts-title sm">
                            Science Village Festival
                          </h3>
                        </div>
                        <p>student@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Village Festival card end */}
                  {/* Crafts and Artisans card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CREATIVITY IN SCIENCE THROUGH TRADITIONS.
                          </p>
                          <h3 className="ts-title sm">
                            Traditional Crafts and Artisans Festival
                          </h3>
                        </div>
                        <p>traditional@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* World Record card */}
                  {/* <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CELEBRATING SCIENTIFIC CREATIVITY THROUGH WORLD
                            RECORDS.
                          </p>
                          <h3 className="ts-title sm">
                            Guinness Book of World Record
                          </h3>
                        </div>
                        <p>guinness@scienceindiafest.org</p>
                        <a href="" className="btn btn-link">
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a>
                        <button className="enter-event">Enter event</button>
                      </div>
                    </div>
                  </div> */}
                  {/* World Record card end */}
                  {/* Games and Toys card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            Celebrating Creativity in Science through Games and
                            Toys.
                          </p>
                          <h3 className="ts-title sm">Games and Toys</h3>
                        </div>
                        <p>gamesandtoys@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall-2")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Games and Toys card end */}
                  {/* Scientists & Technocrats card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle"></p>
                          <h3 className="ts-title sm">
                            Global Indian Scientists &amp; Technocrats Fest
                          </h3>
                        </div>
                        <p>gist@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall-2")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Scientists & Technocrats card end */}
                  {/* Eco Fest card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CREATIVITY IN SCIENCE FOR PRESERVING ECOSYSTEMS.
                          </p>
                          <h3 className="ts-title sm">Eco Fest</h3>
                        </div>
                        <p>ecofest@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall-2")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Eco Fest card end */}
                  {/* New Age Technology card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            SCIENTIFIC CREATIVITY FOR FUTURE.
                          </p>
                          <h3 className="ts-title sm">
                            New Age Technology Show
                          </h3>
                        </div>
                        <p>newage@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall-2")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* New Age Technology card end */}
                  {/* Social Organisation & Institutions card */}
                  <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            CREATIVITY IN SCIENCE FOR SOCIETY.
                          </p>
                          <h3 className="ts-title sm">
                            National Social Organisation &amp; Institutions Meet
                          </h3>
                        </div>
                        <p>nsoim@scienceindiafest.org</p>
                        {/* <a href="" className="btn btn-link" style={{visibility : "hidden"}} >
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a> */}
                        <button
                          className="enter-event"
                          onClick={() => this.redirectEvent("/webinar-hall-2")}
                        >
                          Enter event
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Social Organisation & Institutions card end */}
                  {/* Industry Expo card */}
                  {/* <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <div className="single-item">
                      <div className="content">
                        <div className="eve">
                          <p className="eventsubtitle">
                            DISPLAY OF SCIENTIFIC CREATIVITY.
                          </p>
                          <h3 className="ts-title sm">
                            Mega Science Technology and Industry Expo
                          </h3>
                        </div>
                        <p>expo@scienceindiafest.org</p>
                        <a href="" className="btn btn-link">
                          Know More
                          <i className="material-icons">arrow_forward</i>
                        </a>
                        <button className="enter-event">Enter event</button>
                      </div>
                    </div>
                  </div> */}
                  {/* Industry Expo card end */}
                </div>
              </div>
            </section>
          </>
        )}
        <AlertDialog
          alertDialog={this.state.alertDialog}
          setAlertDialog={this.setAlertDialog}
        />
        {this.state.videoLoading && <LoadingPage />}
        {this.state.isLoginUser && <LandingPage />}
        {this.state.isOpenCertificate && <Certificate isOpen={this.state.isOpenCertificate} onClose={() => {
          this.setState({
            isOpenCertificate: false
          })
        }} />}
      </div>
    );
  }
}
